import { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import { Tree } from 'components/Tree';
import { MenuContext } from 'contexts/MenuContext';
import { RenderTree } from 'models/tree';
import './Sidebar.scss';

export const Sidebar = () => {
  const { userPermissions, currentModulehierarchy } = useContext(MenuContext);
  const [sideBarData, setSideBarData] = useState<RenderTree | null>(null);
  useEffect(() => {
    if (
      currentModulehierarchy &&
      Array.isArray(currentModulehierarchy) &&
      currentModulehierarchy.length
    ) {
      const currentModuleDetails = userPermissions.filter((module: any) => {
        return module.key === currentModulehierarchy[0];
      });
      setSideBarData(currentModuleDetails[0]);
    }
  }, [currentModulehierarchy]);

  return (
    <Grid container className='sidebar-container'>
      <Grid item xs={12}>
        <Tree data={sideBarData} expandAll={true} selectAll={true} />
      </Grid>
    </Grid>
  );
};
