import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import './Notifications.scss';
import { getTodaysNotifications } from 'services/notification.service';
import { isAuthenticated } from 'helpers/auth.helper';
import { isBefore, isAfter } from 'date-fns';

export function Notifications(props: any) {
  const [notificationList, setNotificationList] = useState([]);
  const isLoggedIn = isAuthenticated();

  useEffect(() => {
    // if (props.location) {
    //   getNotifications();
    // }
    getNotifications();
  }, [props.location]);

  // const getNotifications = () => {
  //   getTodaysNotifications()?.then((data: any) => {
  //     if (data && data.data && data.data.data) {
  //       const list = data.data.data;
  //       const objArr = list.filter((item: any) =>
  //         isLoggedIn
  //           ? item.TXT_ISPUBLIC == '0' || item.TXT_ISPUBLIC == '1'
  //           : item.TXT_ISPUBLIC == '1'
  //       );
  //       setNotificationList(objArr);
  //       if (props.getCount) {
  //         props.getCount(objArr.length);
  //       }
  //     }
  //   });
  // };

  const getNotifications = () => {
    getTodaysNotifications()?.then((data: any) => {
      if (data && data.data && data.data.data) {
        let list = data.data.data;
        list = list.filter((notificationItem: any) => {
          const fromDate = new Date(notificationItem.DAT_FROMDATE_STRING);
          fromDate.setTime(fromDate.getTime() - 1 * 60 * 60 * 1000);

          const toDate = new Date(notificationItem.DAT_TODATE_STRING);
          toDate.setTime(toDate.getTime() - 1 * 60 * 60 * 1000);


          return (
            isAfter(new Date(), fromDate) &&
            isBefore(new Date(), toDate)
          );
        });
        const objArr = list.filter((item: any) =>
          isLoggedIn
            ? item.TXT_ISPUBLIC == '0' || item.TXT_ISPUBLIC == '1' || item.TXT_ISPUBLIC == ''
            : item.TXT_ISPUBLIC == '1'
        );
        setNotificationList(objArr);
        if (props.getCount) {
          props.getCount(objArr.length);
        }
      }
    });
  };

  const showNotifications = () => {
    const listOfItems: any = notificationList.map((name: any) => (
      <li key={name.ID_MESSAGE}>{name.TXT_MESSAGE}</li>
    ));
    return <ul className='footer-links'>{listOfItems}</ul>;
  };

  return (
    <Grid className='notifications-main-container'>
      {notificationList.length > 0 && (isLoggedIn || (!isLoggedIn && props.location)) && (
        <Grid container className='app-notifications-container'>
          <Grid item container xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12} sm={12} md={10} lg={10} className='footer-border'>
              {showNotifications()}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
