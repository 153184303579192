import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Sidebar } from 'components/Sidebar';
import { Notifications } from 'components/Notifications';
import { routes } from 'constants/routes';
import './NotificationLayout.scss';

export function NotificationLayout() {
  let location = useLocation();
  const [containerClass, setContainerClass] = useState('secured-content');

  const getCount = (count: any) => {
    count && count > 0
      ? setContainerClass('secured-content-updated')
      : setContainerClass('secured-content');
  };

  return (
    <Grid container className='notification-layout'>
      <Grid item container xs={12} className={containerClass}>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Sidebar />
        </Grid>
        <Grid item container xs={12} sm={12} md={10} lg={10}>
          <Outlet />
        </Grid>
      </Grid>
      <Grid item container xs={12} className='notification-parent'>
        {location.pathname.includes(routes.abrufeLanding.path) ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Notifications getCount={getCount} />
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}
