import { Routes } from 'models/routes';

export const routes: Routes = {
  login: {
    key: null,
    path: '/login'
  },
  home: {
    key: 'A10000',
    path: '/home'
  },
  users: {
    key: 'A51000',
    path: '/users'
  },
  addUser: {
    key: 'A51200',
    path: '/users/addUser'
  },
  editUser: {
    key: 'A51300',
    path: '/users/editUser/:userId/:username'
  },
  passwordmgmt: {
    key: '',
    path: '/users/passwordmgmt/:userId'
  },
  roles: {
    key: 'A52000',
    path: '/roles'
  },
  addRole: {
    key: 'A52200',
    path: '/roles/add'
  },
  editRole: {
    key: 'A52300',
    path: '/roles/edit/:roleId'
  },
  locations: {
    key: 'A61200',
    path: '/location'
  },
  addLocationTab: {
    key: 'A61201',
    path: '/location/add'
  },
  editLocationTab: {
    key: 'A61202',
    path: '/location/edit/:locationId'
  },
  notFound: {
    key: '',
    path: '/404'
  },
  sampleForm: {
    key: '',
    path: 'sample'
  },
  sampleTabs: {
    key: '',
    path: '/tabs'
  },
  internalCostAllocation: {
    key: 'A59000',
    path: '/internalCostAllocation'
  },
  clearRequest: {
    key: 'A50950',
    path: '/ClearRequest'
  },
  adminLanding: {
    key: 'A50000',
    path: '/adminLanding'
  },
  documentCheckout: {
    key: 'A50955',
    path: '/documentcheckout'
  },
  allOrganizationalUnits: {
    key: 'A61000',
    path: '/allorganizations'
  },
  changeHistory: {
    key: '',
    path: '/changeHistory'
  },
  transporter: {
    key: 'A61700',
    path: '/transporter'
  },
  editTransporter: {
    key: 'A61702',
    path: '/transporter/edit/:trasporterId'
  },
  notifications: {
    key: 'A50800',
    path: '/notifications'
  },
  createNotification: {
    key: 'A50801',
    path: '/notifications/create'
  },
  editNotification: {
    key: 'A50802',
    path: '/notifications/edit/:notificationId'
  },
  changepassword: {
    key: 'A51600',
    path: '/changepassword'
  },
  addProducerOrigin: {
    key: 'A61401',
    path: '/producerOrigin/add'
  },
  editProducerOrigin: {
    key: '',
    path: '/producerOrigin/edit/:producerOriginId'
  },
  listProducerOrigin: {
    key: 'A61400',
    path: '/producerOrigin/list'
  },
  addTrasporter: {
    key: 'A61701',
    path: '/transporter/add'
  },
  editTrasporter: {
    key: '',
    path: '/transporter/edit/:transporterId'
  },
  addCorporation: {
    key: 'A61101',
    path: '/corporation/add'
  },
  editCorporation: {
    key: '',
    path: '/corporation/edit/:corporationId'
  },
  listCorporation: {
    key: 'A61100',
    path: '/corporation/list'
  },
  addProducer: {
    key: 'A61301',
    path: '/producer/add'
  },
  editProducer: {
    key: '',
    path: '/producer/edit/:producerId'
  },
  listProducer: {
    key: 'A61300',
    path: '/producer/list'
  },
  storageList: {
    key: 'A61600',
    path: '/storage'
  },
  addNewStorage: {
    key: 'A61601',
    path: '/storage/add'
  },
  editStorage: {
    key: 'A61602',
    path: '/storage/edit/:storageId'
  },
  disposer: {
    key: 'A61500',
    path: '/disposal'
  },
  addDisposer: {
    key: 'A61501',
    path: '/disposal/addDisposal'
  },
  editDisposer: {
    key: '',
    path: '/disposal/edit/:disposalId'
  },
  OSCI: {
    key: 'A50700',
    path: '/OSCI'
  },
  abfallerzeugungen: {
    key: 'A62000',
    path: '/abfallerzeugungen'
  },
  furAnfallstellen: {
    key: 'A62100',
    path: '/furAnfallstellen'
  },
  editFurAnfallstellen: {
    key: 'A62103',
    path: '/furAnfallstellen/edit/:ErzeugerId'
  },

  wasteOrigin: {
    key: 'A63100',
    path: '/waste-origin/list'
  },
  addWasteOrigin: {
    key: 'A63101',
    path: '/waste-origin/add'
  },
  editWasteOrigin: {
    key: 'A63102',
    path: '/waste-origin/edit/:wasteId'
  },
  packageForms: {
    key: 'A63500',
    path: '/packageForms/list'
  },
  addPackageForm: {
    key: 'A635001',
    path: '/packageForms/add'
  },
  editPackageForm: {
    key: 'A63502',
    path: '/packageForms/edit/:gebinderformId'
  },
  catalogue: {
    key: 'A63000',
    path: '/catalogue'
  },
  disposalRoutes: {
    key: 'A64000',
    path: '/disposal-routes'
  },
  pointOfOrigin: {
    key: 'A64100',
    path: '/point-of-origin-to-producer'
  },
  stammdaten: {
    key: 'A60000',
    path: '/stammdaten'
  },
  // abrufeHome: {
  //   key: 'A20000',
  //   path: '/abrufe'
  // },
  abrufeLanding: {
    key: 'A20000',
    path: '/abrufe'
  },
  newAbrufeAnfallstellen: {
    key: 'A21001',
    path: '/abrufe/anfallstellen/new'
  },
  newAbrufeErzeuger: {
    key: 'A22001',
    path: '/abrufe/erzeuger/new'
  },
  newAbrufeSammler: {
    key: 'A23001',
    path: '/abrufe/sammler/new'
  },

  zumAnfallstallen: {
    key: 'A62101',
    path: '/wasteGeneration/zumAbfallstamm/A62101'
  },

  zurAnfallstallen: {
    key: 'A62102',
    path: '/wasteGeneration/zurAnfallstallen/A62102'
  },
  erzeugerList: {
    key: 'A62200',
    path: '/erzeuger/list'
  },
  erzeugerEdit: {
    key: 'A62203',
    path: '/erzeuger/edit/:erzeugerId'
  },
  listAvvKatalog: {
    key: 'A63400',
    path: '/avvkatalog/list'
  },
  addTemporaryStorage: {
    key: 'A61801',
    path: '/temporaryStorage/add'
  },
  editTemporaryStorage: {
    key: 'A61802',
    path: '/temporaryStorage/edit/:temporaryStorageId'
  },
  listTemporaryStorage: {
    key: 'A61800',
    path: '/temporaryStorage/list'
  },
  collectorToDisposer: {
    key: 'A64300',
    path: '/collector-to-disposer'
  },
  producerToDisposer: {
    key: 'A64200',
    path: '/producer-to-disposer'
  },
  furSammlerList: {
    key: 'A62300',
    path: '/fur-sammler/list'
  },
  listLeistungsarten: {
    key: 'A63300',
    path: '/leistungsarten/list'
  },
  addLeistungsarten: {
    key: 'A63301',
    path: '/leistungsarten/add'
  },
  editLeistungsarten: {
    key: 'A63302',
    path: '/leistungsarten/edit/:leistungsartenformId'
  },
  zumErzeuger: {
    key: 'A62202',
    path: '/zum-Erzeuger'
  },
  furSammlerEdit: {
    key: '',
    path: '/fur-sammler/edit/:id'
  },
  zumAbfallstamm: {
    key: 'A62201',
    path: '/zum-abfallstamm'
  },
  userauthorize: {
    key: '',
    path: '/users/authorize/:userId/:username'
  },
  autodispositionen: {
    key: 'A62400',
    path: '/autodispositionen'
  },

  editTeilnehmer: {
    key: '',
    path: '/teilnehmer/edit/:teilnehmerType/:teilnehmerID'
  },
  listTeilnehmer: {
    key: 'A61900',
    path: '/teilnehmer'
  },
  listCostCenter: {
    key: 'A63200',
    path: '/costCenter/list'
  },
  editCostCenter: {
    key: '',
    path: '/costCenter/edit/:costCenterId'
  },
  addNewCostCenter: {
    key: 'A63201',
    path: '/costCenter/add'
  },

  eVentoLanding: {
    key: 'A70000',
    path: '/evento'
  },
  individualReportsList: {
    key: 'A67000',
    path: '/individual-reports'
  },
  sammlerZumAbfallstamm: {
    key: 'A62301',
    path: '/sammler/abfallstamm'
  },
  annahmeList: {
    key: 'A41000',
    path: '/lager/annahme'
  },
  sammlerZumSammler: {
    key: 'A62302',
    path: '/sammler/zumsammler'
  },
  individualReportsAdd: {
    key: 'A67100',
    path: '/individual-reports/add'
  },
  individualReportsEdit: {
    key: 'A67200',
    path: '/individual-reports/edit/:reportId'
  },
  lagerList: {
    key: 'A40000',
    path: '/lager'
  },
  lagerOrganisieren: {
    key: 'A43000',
    path: '/lagerOrganisieren/edit/:organizeId'
  },

  dispoList: {
    key: 'A30000',
    path: '/dispo/list'
  },
  dispoEdit: {
    key: 'A31100',
    path: '/dispo/edit/:dispoId'
  },

  //Entsorgen
  NachAnlieferung: {
    key: 'A33010',
    path: '/entsorgen/nachAnlieferung/:ID'
  },
  NachMenge: {
    key: 'A33020',
    path: '/entsorgen/NachMenge/:ID'
  },
  ausEntrosgungsweg: {
    key: 'A65100',
    path: '/ausEntrosgungsweg'
  },

  editEntsorgungsakten: {
    key: '',
    path: '/entsorgungsakten/edit/:entId'
  },
  entsorgenHome: {
    key: 'A45000',
    path: '/entsorgen'
  },
  internalEntsorgerList: {
    key: 'A33000',
    path: '/entsorgen/internalEntsorgen/List'
  },
  logbuch: {
    key: 'A33030',
    path: '/entsorgen/logbuch'
  },
  acceptedDeliveries: {
    key: 'A42000',
    path: '/lager/angenommene'
  },
  korrekturAnnahme: {
    key: 'A42500',
    path: '/korrektur/:Id'
  },
  abrufeFurAnfallstellen: {
    key: 'A21000',
    path: '/abrufe/furAnfallstellen'
  },
  abrufeInternalBilling: {
    key: 'A21010',
    path: '/abrufe/internalBilling'
  },
  geloschteDokumente: {
    key: 'A70002',
    path: '/evento/geloschte-dokumente'
  },
  advancesTableLink: {
    key: '',
    path: '/advanced-table'
  },
  ExternalLogbuch: {
    key: 'A34030',
    path: '/entsorgen/externalEntsorgen/logbuch'
  },
  ExternalVorgangEdit: {
    key: 'A34031',
    path: '/entsorgen/externalEntsorgen/vorgang/:Id'
  },
  ExternalEntsorgenLogbuchDelete: {
    key: 'A34032',
    path: '/entsorgen/externalEntsorgen/Deletelogbuch/:Id'
  },
  entsorgungsaktenList: {
    key: 'A65000',
    path: '/entsorgungsakten/list'
  },
  sammelentsorgungsnachweiseList: {
    key: 'A71100',
    path: '/evento/sammelentsorgungsnachweise'
  },
  agsBescheidList: {
    key: 'A72060',
    path: '/evento/sonstige-document/agsBescheide'
  },
  quittungenDataList: {
    key: 'A72140',
    path: '/evento/sonstige-document/quittungen'
  },
  registrierungsquittungList: {
    key: 'A72230',
    path: '/evento/sonstige-document/registrierungsquittungList'
  },
  registerAnforderungenListPlannerView: {
    key: 'A72200',
    path: '/evento/sonstige-document/registerAnforderungenListPlannerView'
  },
  dokumentBrowser: {
    key: 'A70003',
    path: '/evento/dokument-browser'
  },
  eVentoMeineDokumente: {
    key: 'A70001',
    path: '/evento/MeineDokumente'
  },
  begleitscheineList: {
    key: 'A71150',
    path: '/begleitscheine/list'
  },
  eVentoNachweiseListView: {
    key: 'A71050',
    path: '/eVentoNachweiseList'
  },
  eventoUbernahmescheineList: {
    key: 'A71200',
    path: '/evento/ubernahmescheineList'
  },

  entsorgungsnachweiseEdit: {
    key: 'A71055',
    path: '/entsorgungsnachweise/edit/:entsorgungsnachweiseId'
  },
  fromeANVentsorgungsnachweiseEdit: {
    key: 'A71055',
    path: '/entsorgungsnachweise/edit/:entsorgungsnachweiseId/:from/:to'
  },
  toeANVentsorgungsnachweiseEdit: {
    key: 'A71055',
    path: '/entsorgungsakten/edit/:to'
  },
  externalEntsorgenNachMenge: {
    key: 'A34020',
    path: '/entsorgen/externalEntsorgen/nachMenge/:entId'
  },
  externalEntsorgenList: {
    key: 'A34000',
    path: '/entsorgen/externalEntsorgen/list'
  },
  abrufeFurErzeuger: {
    key: 'A22000',
    path: '/abrufe/furErzeuger'
  },
  abrufeFurSammler: {
    key: 'A23000',
    path: '/abrufe/furSammler'
  },
  beendeteAbrufeAnfallstellen: {
    key: 'A21002',
    path: '/abrufe/furAnfallstellen/beendeteAbrufe'
  },
  beendeteAbrufeErzeuger: {
    key: 'A22002',
    path: '/abrufe/furErzeuger/beendeteAbrufe'
  },
  beendeteAbrufeSammler: {
    key: 'A23002',
    path: '/abrufe/furSammler/beendeteAbrufe'
  },
  ubernahmescheinEdit: {
    key: 'A71205',
    path: '/ubernahmescheine/edit/:ubernahmescheineId'
  },
  ubernahmescheinDetail: {
    key: '',
    path: '/ubernahmescheine/detail/:ubernahmescheineId'
  },
  dokumentBrowserUnsEdit: {
    key: 'A71205',
    path: '/ubernahmescheine/edit/:ubernahmescheineId/:from'
  },
  konfliktdikumenteList: {
    key: 'A70250',
    path: '/evento/Konfliktdikumente'
  },
  BGSNrAnforderungenList: {
    key: 'A72070',
    path: '/evento/sonstige-document/BGSNrAnforderungen'
  },
  BGSNrZuteilung: {
    key: 'A72080',
    path: '/evento/sonstige-document/BGSNrZuteilung'
  },
  mitteilungenList: {
    key: 'A72120',
    path: '/evento/sonstige-document/mitteilungen'
  },
  externalEntsorgenNachAnlieferungen: {
    key: 'A34010',
    path: '/entsorgen/externalEntsorgen/nachAnlieferungen/:entId'
  },
  begleitscheineEdit: {
    key: 'A71155',
    path: '/begleitscheine/edit/:begleitscheineId'
  },
  begleitscheineDetail: {
    key: 'A71155',
    path: '/begleitscheine/detail/:begleitscheineId'
  },
  sonstigemainscreen: {
    key: 'A72000',
    path: '/evento/sonstigemainscreen'
  },
  efgdocumente: {
    key: 'A72100',
    path: '/evento/efgdocumente'
  },
  daDokumenteList: {
    key: 'A72090',
    path: '/evento/sonstige-document/daDokumente'
  },
  editAbrufeAnfallstellen: {
    key: 'A20500',
    path: '/abrufe/anfallstellen/edit/:abruf/:abrufeId'
  },
  editAbrufeErzeuger: {
    key: 'A20500',
    path: '/abrufe/erzeuger/edit/:abruf/:abrufeId'
  },
  editAbrufeSammler: {
    key: 'A20500',
    path: '/abrufe/sammler/edit/:abruf/:abrufeId'
  },
  eAnvNachweise: {
    key: '',
    path: '/entsorgungsakten/edit/:uuid/eAnvNachweise'
  },
  externalVorgangEditUpload: {
    key: 'A34031',
    path: '/entsorgen/externalEntsorgen/vorgang/:tab/:Id'
  },
  RegisterAuszuge: {
    key: 'A72210',
    path: '/evento/sonstige-document/RegisterAuszuge'
  },
  bGSNrAnforderungenEdit: {
    key: '',
    path: '/evento/sonstige-document/BGSNrAnforderungen/edit/:bgsNrId'
  },
  bGSNrAnforderungenUNSEdit: {
    key: '',
    path: '/evento/sonstige-document/BGSNrAnforderungen/edit/:bgsNrId/:from'
  },
  sammelentsorgungsnachweiseEdit: {
    key: 'A71105',
    path: '/evento/sammelentsorgungsnachweise/edit/:sammelentsorgungsnachweiseId'
  },
  makler: {
    key: 'A61910',
    path: '/makler'
  },
  createMakler: {
    key: 'A61911',
    path: '/makler/add'
  },
  editMakler: {
    key: 'A61912',
    path: '/makler/edit/:maklerId'
  },
  provider: {
    key: 'A61930',
    path: '/provider'
  },
  editProvider: {
    key: 'A61932',
    path: '/provider/edit/:providerId'
  },
  newProvider: {
    key: 'A61931',
    path: '/provider/add'
  },
  listSonstige: {
    key: 'A61920',
    path: '/sonstige'
  },
  editSonstige: {
    key: 'A61922',
    path: '/sonstige/edit/:sonstigeId'
  },
  newSonstige: {
    key: 'A61921',
    path: '/sonstige/add'
  },
  konfliktDokumenteBegleitscheineEdit: {
    key: 'A71155',
    path: '/Begleitscheine/edit/:begleitscheineId/:from'
  },
  konfliktDokumenteSammelentsorgungsnachweiseEdit: {
    key: 'A71105',
    path: '/evento/sammelentsorgungsnachweise/edit/:sammelentsorgungsnachweiseId/:from'
  },
  konfliktDokumenteUbernahmescheinEdit: {
    key: 'A71205',
    path: '/ubernahmescheine/edit/:ubernahmescheineId/:from'
  },
  konfliktDokumenteEntsorgungsnachweiseEdit: {
    key: 'A71055',
    path: '/entsorgungsnachweise/edit/:entsorgungsnachweiseId/:from'
  },
  neuErstellen: {
    key: 'A720701',
    path: '/neuErstellen/bgsNr'
  },
  mitteilungenNeuErstellen: {
    key: 'A721201',
    path: '/neuErstellen/mitteilungen'
  },
  entsorgungsnachweiseDetailPage: {
    key: '',
    path: '/entsorgungsnachweise/detail/:entsorgungsnachweiseId'
  },
  sammlerEntsorgungsnachweiseDetailPage: {
    key: '',
    path: '/sammelentsorgungsnachweise/detail/:sammelentsorgungsnachweiseId/:from'
  },
  fromEntsorgungsnachweiseDetail: {
    key: '',
    path: '/entsorgungsnachweise/detail/:entsorgungsnachweiseId/:from/:to'
  },
  toEntsorgungsnachweiseDetail: {
    key: '',
    path: '/entsorgungsnachweise/detail/:entsorgungsnachweiseId/:from/:to'
  },
  fromSammlerEntsorgungsnachweiseDetail: {
    key: '',
    path: '/sammelentsorgungsnachweise/detail/:sammelentsorgungsnachweiseId/:from/:to'
  },
  toSammlerEntsorgungsnachweiseDetail: {
    key: '',
    path: '/sammelentsorgungsnachweise/detail/:sammelentsorgungsnachweiseId/:from/:to'
  },
  fromeANVSammlerEntsorgungsnachweiseEdit: {
    key: 'A71105',
    path: '/sammelentsorgungsnachweise/edit/:sammelentsorgungsnachweiseId/:from/:to'
  },
  toeAnvEdit: {
    key: 'A71055',
    path: '/entsorgungsnachweise/edit/:entsorgungsnachweiseId/:from'
  }
};
