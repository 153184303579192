import { apiEndpoints } from 'core/api/apiEndpoints';
import { httpRequest } from 'core/api/httpRequest';

/**
 * Get notification list
 * @returns
 */
const getNotificationList = () => {
  return httpRequest(apiEndpoints.notificationList, 'get');
};

/**
 * Get notification lists of today
 * @returns
 */
const getTodaysNotifications = () => {
  return httpRequest(apiEndpoints.notificationListToday, 'get');
};

/**
 * create new
 * @param notificationObj
 * @returns
 */
const createNotification = (notificationObj: any) => {
  return httpRequest(apiEndpoints.createNotification, 'post', notificationObj);
};

/**
 * Update notification details
 * @param notificationObj
 * @returns
 */
const updateNotification = (notificationObj: any) => {
  return httpRequest(apiEndpoints.updateNotification, 'post', notificationObj);
};

/**
 * Get notification details
 * @returns
 */
const getNotificationDetails = (id: any) => {
  const endPoint = {
    url: apiEndpoints.notificationDetails.url + `?ID_MESSAGE=${id}`,
    isMock: false
  };
  return httpRequest(endPoint, 'get');
};

/**
 * Get notification details
 * @returns
 */
const deleteNotification = (id: any) => {
  const endPoint = {
    url: apiEndpoints.deleteNotification.url + `?ID_MESSAGE=${id}`,
    isMock: false
  };
  return httpRequest(endPoint, 'get');
};

export {
  getNotificationList,
  createNotification,
  getNotificationDetails,
  updateNotification,
  deleteNotification,
  getTodaysNotifications
};
