import { getFromStorage } from 'core/utils/utils.helper';
import { AuthResponse } from 'models/auth';
import { STORAGE_ACCESS_TOKEN } from 'constants/storage';

/**
 * Modify login API response into a specific format
 * @param data
 * @returns
 */
const formatAuthInfo = (data: any) => {
  const authData: AuthResponse = {
    accessToken: data && data.access_token ? data.access_token : '',
    refreshToken: data && data.refresh_token ? data.refresh_token : ''
  };
  return authData;
};

/**
 * Returns a flag based on the user authentication status
 */
const isAuthenticated = () => {
  const authenticated = getFromStorage(STORAGE_ACCESS_TOKEN) ? true : false;
  return authenticated;
};

export { formatAuthInfo, isAuthenticated };
