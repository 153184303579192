import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import { routes } from 'constants/routes';
import { PlainLayout } from 'layouts/PlainLayout';
import { SidebarLayout } from 'layouts/SidebarLayout';
import { NotificationLayout } from 'layouts/NotificationLayout';

const PublicLayout = React.lazy(() =>
  import('layouts/PublicLayout').then(({ PublicLayout }) => ({
    default: PublicLayout
  }))
);
const SecuredLayout = React.lazy(() =>
  import('layouts/SecuredLayout').then(({ SecuredLayout }) => ({
    default: SecuredLayout
  }))
);
const Home = React.lazy(() =>
  import('pages/HomePage/Home').then(({ Home }) => ({ default: Home }))
);
const Login = React.lazy(() =>
  import('pages/Login').then(({ Login }) => ({
    default: Login
  }))
);
const Roles = React.lazy(() =>
  import('pages/Admin/RoleModule/Roles').then(({ Roles }) => ({
    default: Roles
  }))
);
const AddRole = React.lazy(() =>
  import('pages/Admin/RoleModule/AddRole').then(({ AddRole }) => ({
    default: AddRole
  }))
);
const EditRole = React.lazy(() =>
  import('pages/Admin/RoleModule/EditRole').then(({ EditRole }) => ({
    default: EditRole
  }))
);
const AddUser = React.lazy(() =>
  import('pages/Admin/UserModule/AddUser').then(({ AddUser }) => ({
    default: AddUser
  }))
);
const EditUser = React.lazy(() =>
  import('pages/Admin/UserModule/EditUser').then(({ EditUser }) => ({
    default: EditUser
  }))
);
const UserAuthorize = React.lazy(() =>
  import('pages/Admin/UserModule/UserAuthorize').then(({ UserAuthorize }) => ({
    default: UserAuthorize
  }))
);
const UserList = React.lazy(() =>
  import('pages/Admin/UserModule/UserList').then(({ UserList }) => ({
    default: UserList
  }))
);
const Passwordmgmt = React.lazy(() =>
  import('pages/Admin/UserModule/Passwordmgmt').then(({ Passwordmgmt }) => ({
    default: Passwordmgmt
  }))
);
const NotFound = React.lazy(() =>
  import('pages/NotFound').then(({ NotFound }) => ({
    default: NotFound
  }))
);
const SampleForm = React.lazy(() =>
  import('pages/FormElementsSamples').then(({ FormElementsSamples }) => ({
    default: FormElementsSamples
  }))
);
const Tabhome = React.lazy(() =>
  import('components/Tabhome').then(({ Tabhome }) => ({
    default: Tabhome
  }))
);
const Landing = React.lazy(() =>
  import('pages/Admin/InternalCostAllocationModule/Landing').then(({ Landing }) => ({
    default: Landing
  }))
);
const ClearReqLanding = React.lazy(() =>
  import('pages/Admin/ClearRequestModule/ClearReqLanding').then(({ ClearReqLanding }) => ({
    default: ClearReqLanding
  }))
);
const DocumentCheckout = React.lazy(() =>
  import('pages/Admin/DocumentCheckoutModule/DocumentCheckout').then(({ DocumentCheckout }) => ({
    default: DocumentCheckout
  }))
);
const AdminLanding = React.lazy(() =>
  import('pages/Admin/AdminLanding/AdminLanding').then(({ AdminLanding }) => ({
    default: AdminLanding
  }))
);
const ChangeHistory = React.lazy(() =>
  import('pages/HomePage/ChangeHistory').then(({ ChangeHistory }) => ({
    default: ChangeHistory
  }))
);
const AllOrganizationalUnits = React.lazy(() =>
  import('pages/MasterData/Organization/AllOrganizationalUnits').then(
    ({ AllOrganizationalUnits }) => ({
      default: AllOrganizationalUnits
    })
  )
);
const AddLocationTab = React.lazy(() =>
  import('pages/MasterData/Organization/LocationModule/AddLocation').then(({ AddLocationTab }) => ({
    default: AddLocationTab
  }))
);
const EditLocationTab = React.lazy(() =>
  import('pages/MasterData/Organization/LocationModule/EditLocation').then(
    ({ EditLocationTab }) => ({
      default: EditLocationTab
    })
  )
);
const Locations = React.lazy(() =>
  import('pages/MasterData/Organization/LocationModule/Locations').then(({ Locations }) => ({
    default: Locations
  }))
);
const AbrufeLanding = React.lazy(() =>
  import('pages/Abrufe/LandingPage').then(({ LandingPage }) => ({
    default: LandingPage
  }))
);
const CreateAbrufeAnfallstellen = React.lazy(() =>
  import('pages/Abrufe/CreateAbrufeAnfallstellen').then(({ CreateAbrufeAnfallstellen }) => ({
    default: CreateAbrufeAnfallstellen
  }))
);
const CreateAbrufeErzeuger = React.lazy(() =>
  import('pages/Abrufe/CreateAbrufeErzeuger').then(({ CreateAbrufeErzeuger }) => ({
    default: CreateAbrufeErzeuger
  }))
);
const CreateAbrufeSammler = React.lazy(() =>
  import('pages/Abrufe/CreateAbrufeSammler').then(({ CreateAbrufeSammler }) => ({
    default: CreateAbrufeSammler
  }))
);
const PlannerView = React.lazy(() =>
  import('pages/MasterData/Organization/Transporter/PlannerView').then(({ PlannerView }) => ({
    default: PlannerView
  }))
);
const PlannerEdit = React.lazy(() =>
  import('pages/MasterData/Organization/Transporter/PlannerEdit').then(({ PlannerEdit }) => ({
    default: PlannerEdit
  }))
);
const MaklerPlannerEdit = React.lazy(() =>
  import('pages/MasterData/Organization/Makler/MaklerPlannerEdit').then(
    ({ MaklerPlannerEdit }) => ({
      default: MaklerPlannerEdit
    })
  )
);

const ProviderPlannerEdit = React.lazy(() =>
  import('pages/MasterData/Organization/Provider/ProviderPlannerEdit').then(
    ({ ProviderPlannerEdit }) => ({
      default: ProviderPlannerEdit
    })
  )
);
const NotificationList = React.lazy(() =>
  import('pages/Admin/Notifications/NotificationList').then(({ NotificationList }) => ({
    default: NotificationList
  }))
);
const ChangePassword = React.lazy(() =>
  import('pages/Admin/ChangePassword').then(({ ChangePassword }) => ({
    default: ChangePassword
  }))
);

const CreateNotification = React.lazy(() =>
  import('pages/Admin/Notifications/CreateNotification').then(({ CreateNotification }) => ({
    default: CreateNotification
  }))
);

const EditNotification = React.lazy(() =>
  import('pages/Admin/Notifications/EditNotification').then(({ EditNotification }) => ({
    default: EditNotification
  }))
);

const AddProducerOrigin = React.lazy(() =>
  import('pages/MasterData/Organization/ProducerOrigin/AddProducerOrigin').then(
    ({ AddProducerOrigin }) => ({
      default: AddProducerOrigin
    })
  )
);
const EditProducerOrigin = React.lazy(() =>
  import('pages/MasterData/Organization/ProducerOrigin/EditProducerOrigin').then(
    ({ EditProducerOrigin }) => ({
      default: EditProducerOrigin
    })
  )
);
const ListProducerOrigin = React.lazy(() =>
  import('pages/MasterData/Organization/ProducerOrigin/ListProducerOrigin').then(
    ({ ListProducerOrigin }) => ({
      default: ListProducerOrigin
    })
  )
);
const NewPlanner = React.lazy(() =>
  import('pages/MasterData/Organization/Transporter/NewPlanner').then(({ NewPlanner }) => ({
    default: NewPlanner
  }))
);

const NewCorporation = React.lazy(() =>
  import('pages/MasterData/Organization/Corporation/NewCorporation').then(({ NewCorporation }) => ({
    default: NewCorporation
  }))
);

const CreateNewProducer = React.lazy(() =>
  import('pages/MasterData/Organization/Producer/NewProducer').then(({ CreateNewProducer }) => ({
    default: CreateNewProducer
  }))
);

const CorporationEdit = React.lazy(() =>
  import('pages/MasterData/Organization/Corporation/CorporationEdit').then(
    ({ CorporationEdit }) => ({
      default: CorporationEdit
    })
  )
);
const ProducerEdit = React.lazy(() =>
  import('pages/MasterData/Organization/Producer/ProducerEdit').then(({ ProducerEdit }) => ({
    default: ProducerEdit
  }))
);

const CorporationList = React.lazy(() =>
  import('pages/MasterData/Organization/Corporation/CorporationList').then(
    ({ CorporationList }) => ({
      default: CorporationList
    })
  )
);

const ProducerList = React.lazy(() =>
  import('pages/MasterData/Organization/Producer/ProducerList').then(({ ProducerList }) => ({
    default: ProducerList
  }))
);

const StorageList = React.lazy(() =>
  import('pages/MasterData/Organization/Storage/StorageList').then(({ StorageList }) => ({
    default: StorageList
  }))
);
const NewStorage = React.lazy(() =>
  import('pages/MasterData/Organization/Storage/NewStorage').then(({ NewStorage }) => ({
    default: NewStorage
  }))
);
const EditStorage = React.lazy(() =>
  import('pages/MasterData/Organization/Storage/EditStorage').then(({ EditStorage }) => ({
    default: EditStorage
  }))
);
const ListDisposer = React.lazy(() =>
  import('pages/MasterData/Organization/Disposer/ListDisposer').then(({ ListDisposer }) => ({
    default: ListDisposer
  }))
);

const AddDisposer = React.lazy(() =>
  import('pages/MasterData/Organization/Disposer/AddDisposer').then(({ AddDisposer }) => ({
    default: AddDisposer
  }))
);

const EditDisposer = React.lazy(() =>
  import('pages/MasterData/Organization/Disposer/EditDisposer').then(({ EditDisposer }) => ({
    default: EditDisposer
  }))
);
const OSCI = React.lazy(() =>
  import('pages/Admin/OSCI').then(({ OSCI }) => ({
    default: OSCI
  }))
);
const Abfallerzeugungen = React.lazy(() =>
  import('pages/MasterData/Abfallerzeugungen').then(({ Abfallerzeugungen }) => ({
    default: Abfallerzeugungen
  }))
);
const FurAnfallstellen = React.lazy(() =>
  import('pages/MasterData/Abfallerzeugungen/FurAnfallstellen').then(({ FurAnfallstellen }) => ({
    default: FurAnfallstellen
  }))
);

const FurSammlerList = React.lazy(() =>
  import('pages/MasterData/Abfallerzeugungen/FurSammler/FurSammlerList').then(
    ({ FurSammlerList }) => ({
      default: FurSammlerList
    })
  )
);

const FurSammlerEdit = React.lazy(() =>
  import('pages/MasterData/Abfallerzeugungen/FurSammler/FurSammlerEdit').then(
    ({ FurSammlerEdit }) => ({
      default: FurSammlerEdit
    })
  )
);

const ListWasteOrigin = React.lazy(() =>
  import('pages/MasterData/Kataloge/WasteOrigins/ListWasteOrigin').then(({ ListWasteOrigin }) => ({
    default: ListWasteOrigin
  }))
);

const ListPackageForms = React.lazy(() =>
  import('pages/MasterData/Kataloge/Gebindeformen/ListPackageForms').then(
    ({ ListPackageForms }) => ({
      default: ListPackageForms
    })
  )
);
const EditWasteOrigin = React.lazy(() =>
  import('pages/MasterData/Kataloge/WasteOrigins/EditWasteOrigin').then(({ EditWasteOrigin }) => ({
    default: EditWasteOrigin
  }))
);

const AddPackageForm = React.lazy(() =>
  import('pages/MasterData/Kataloge/Gebindeformen/AddPackageForm').then(({ AddPackageForm }) => ({
    default: AddPackageForm
  }))
);

const FurAnfallstellenEdit = React.lazy(() =>
  import('pages/MasterData/Abfallerzeugungen/FurAnfallstellenEdit').then(
    ({ FurAnfallstellenEdit }) => ({
      default: FurAnfallstellenEdit
    })
  )
);
const EditPackageForm = React.lazy(() =>
  import('pages/MasterData/Kataloge/Gebindeformen/EditPackageForm').then(({ EditPackageForm }) => ({
    default: EditPackageForm
  }))
);

const CatalogueLandingScreen = React.lazy(() =>
  import('pages/MasterData/Kataloge/CatalogueLanding').then(({ CatalogueLanding }) => ({
    default: CatalogueLanding
  }))
);

const DisposalRoutesLandingScreen = React.lazy(() =>
  import('pages/MasterData/DisposalRoutes/DisposalRoutesLanding').then(
    ({ DisposalRoutesLanding }) => ({
      default: DisposalRoutesLanding
    })
  )
);

const PointOfOrigin = React.lazy(() =>
  import('pages/MasterData/DisposalRoutes/PointOfOrigin').then(({ PointOfOrigin }) => ({
    default: PointOfOrigin
  }))
);
const StammdatenMainScreen = React.lazy(() =>
  import('pages/MasterData/StammdatenMainScreen').then(({ StammdatenMainScreen }) => ({
    default: StammdatenMainScreen
  }))
);
const ErzeugerList = React.lazy(() =>
  import('pages/MasterData/Abfallerzeugungen/FurErzeuger/Erzeuger/ErzeugerList').then(
    ({ ErzeugerList }) => ({
      default: ErzeugerList
    })
  )
);
const ErzeugerEdit = React.lazy(() =>
  import('pages/MasterData/Abfallerzeugungen/FurErzeuger/Erzeuger/ErzeugerEdit').then(
    ({ ErzeugerEdit }) => ({
      default: ErzeugerEdit
    })
  )
);
const AddWasteSource = React.lazy(() =>
  import('pages/MasterData/Kataloge/WasteOrigins/AddWasteSource').then(({ AddWasteSource }) => ({
    default: AddWasteSource
  }))
);

const AvvKatalogList = React.lazy(() =>
  import('pages/MasterData/Kataloge/AvvKatalog/AvvKatalogList').then(({ AvvKatalogList }) => ({
    default: AvvKatalogList
  }))
);
const ZumErzeuger = React.lazy(() =>
  import('pages/MasterData/Abfallerzeugungen/FurErzeuger/ZumErzeuger').then(({ ZumErzeuger }) => ({
    default: ZumErzeuger
  }))
);

const Autodispositionen = React.lazy(() =>
  import('pages/MasterData/Abfallerzeugungen/Autodispositionen').then(({ Autodispositionen }) => ({
    default: Autodispositionen
  }))
);
const CollectorToDisposer = React.lazy(() =>
  import('pages/MasterData/DisposalRoutes/CollectorToDisposer').then(({ CollectorToDisposer }) => ({
    default: CollectorToDisposer
  }))
);

const ProducerToDisposer = React.lazy(() =>
  import('pages/MasterData/DisposalRoutes/ProducerToDisposer').then(({ ProducerToDisposer }) => ({
    default: ProducerToDisposer
  }))
);

const WasteGeneration = React.lazy(() =>
  import('pages/MasterData/WasteGeneration/Anfallstellen/ZumAbfallstamm').then(
    ({ ZumAbfallstamm }) => ({
      default: ZumAbfallstamm
    })
  )
);

const SammlerAbfallstamm = React.lazy(() =>
  import('pages/MasterData/WasteGeneration/Sammler/ZumAbfallstamm').then(({ ZumAbfallstamm }) => ({
    default: ZumAbfallstamm
  }))
);

const WasteGenerationZurAnfallstelle = React.lazy(() =>
  import('pages/MasterData/WasteGeneration/Anfallstellen/ZurAnfallstelle').then(
    ({ ZurAnfallstelle }) => ({
      default: ZurAnfallstelle
    })
  )
);
const ZumAbfallstamm = React.lazy(() =>
  import('pages/MasterData/Abfallerzeugungen/FurErzeuger/ZumAbfallstamm').then(
    ({ ZumAbfallstamm }) => ({
      default: ZumAbfallstamm
    })
  )
);

const LeistungsartenList = React.lazy(() =>
  import('pages/MasterData/Kataloge/Leistungsarten/LeistungsartenList').then(
    ({ LeistungsartenList }) => ({
      default: LeistungsartenList
    })
  )
);

const AddLeistungsarten = React.lazy(() =>
  import('pages/MasterData/Kataloge/Leistungsarten/AddLeistungsarten').then(
    ({ AddLeistungsarten }) => ({
      default: AddLeistungsarten
    })
  )
);

const EditLeistungsarten = React.lazy(() =>
  import('pages/MasterData/Kataloge/Leistungsarten/EditLeistungsarten').then(
    ({ EditLeistungsarten }) => ({
      default: EditLeistungsarten
    })
  )
);

const TemporaryStorageList = React.lazy(() =>
  import('pages/MasterData/Organization/TemporaryStorage/TemporaryStorageList').then(
    ({ TemporaryStorageList }) => ({
      default: TemporaryStorageList
    })
  )
);

const NewTemporaryStorage = React.lazy(() =>
  import('pages/MasterData/Organization/TemporaryStorage/NewTemporaryStorage').then(
    ({ NewTemporaryStorage }) => ({
      default: NewTemporaryStorage
    })
  )
);

const TemporaryStorageEdit = React.lazy(() =>
  import('pages/MasterData/Organization/TemporaryStorage/TemporaryStorageEdit').then(
    ({ TemporaryStorageEdit }) => ({
      default: TemporaryStorageEdit
    })
  )
);

const EditTeilNehmer = React.lazy(() =>
  import('pages/MasterData/Organization/Teilnehmer/TeilnehmerEdit').then(({ TeilnehmerEdit }) => ({
    default: TeilnehmerEdit
  }))
);

const ListTeilNehmer = React.lazy(() =>
  import('pages/MasterData/Organization/Teilnehmer/TeilnehmerList').then(({ TeilnehmerList }) => ({
    default: TeilnehmerList
  }))
);

const CostCenterList = React.lazy(() =>
  import('pages/MasterData/Kataloge/CostCenter/CostCenterLsit/CostCenterList').then(
    ({ CostCenterList }) => ({
      default: CostCenterList
    })
  )
);
const CostCenterEdit = React.lazy(() =>
  import('pages/MasterData/Kataloge/CostCenter/CostCenterEdit/CostCenterEdit').then(
    ({ CostCenterEdit }) => ({
      default: CostCenterEdit
    })
  )
);
const CostCenterAdd = React.lazy(() =>
  import('pages/MasterData/Kataloge/CostCenter/NewCostCenter/NewCostCenter').then(
    ({ NewCostCenter }) => ({
      default: NewCostCenter
    })
  )
);
const AnnahmeList = React.lazy(() =>
  import('pages/Lager/AnnahmeList/AnnahmeList').then(({ AnnahmeList }) => ({
    default: AnnahmeList
  }))
);

const SammlerZumSammler = React.lazy(() =>
  import('pages/MasterData/WasteGeneration/Sammler/ZumSammler').then(({ ZumSammler }) => ({
    default: ZumSammler
  }))
);
const LagerList = React.lazy(() =>
  import('pages/Lager/LagerList').then(({ LagerList }) => ({
    default: LagerList
  }))
);

const Organisieren = React.lazy(() =>
  import('pages/Lager/Organisieren').then(({ Organisieren }) => ({
    default: Organisieren
  }))
);

const DispoList = React.lazy(() =>
  import('pages/Dispo/DispoList').then(({ DispoList }) => ({
    default: DispoList
  }))
);

const DispoEdit = React.lazy(() =>
  import('pages/Dispo/DispoEdit').then(({ DispoEdit }) => ({
    default: DispoEdit
  }))
);

const IndividualReportsList = React.lazy(() =>
  import('pages/MasterData/IndividualReports/IndividualReportsList').then(
    ({ IndividualReportsList }) => ({
      default: IndividualReportsList
    })
  )
);
const IndividualReportsAdd = React.lazy(() =>
  import('pages/MasterData/IndividualReports/IndividualReportsAdd').then(
    ({ IndividualReportsAdd }) => ({
      default: IndividualReportsAdd
    })
  )
);
const IndividualReportsEdit = React.lazy(() =>
  import('pages/MasterData/IndividualReports/IndividualReportsEdit').then(
    ({ IndividualReportsEdit }) => ({
      default: IndividualReportsEdit
    })
  )
);

const InternalEntsorgungNachAnlieferung = React.lazy(() =>
  import('pages/Entsorgen/EntsorgenInternalEntsorgung/NachAnlieferung').then(
    ({ NachAnlieferung }) => ({
      default: NachAnlieferung
    })
  )
);
const InternalEntsorgungNachMenge = React.lazy(() =>
  import('pages/Entsorgen/EntsorgenInternalEntsorgung/NachMenge').then(({ NachMenge }) => ({
    default: NachMenge
  }))
);

const CreateNewDisposalFile = React.lazy(() =>
  import('pages/MasterData/Entsorgungsakten/CreateNewDisposalFiles').then(
    ({ CreateNewDisposalFile }) => ({
      default: CreateNewDisposalFile
    })
  )
);

const EditEntsorgungsakten = React.lazy(() =>
  import('pages/MasterData/Entsorgungsakten/EditEntsorgungsakten/EditEntsorgungsakten').then(
    ({ EditEntsorgungsakten }) => ({
      default: EditEntsorgungsakten
    })
  )
);

const EntsorgenLandingPage = React.lazy(() =>
  import('pages/Entsorgen/EntsorgenLandingPage/EntsorgenLandingPage').then(
    ({ EntsorgenLandingPage }) => ({
      default: EntsorgenLandingPage
    })
  )
);
const EntsorgenList = React.lazy(() =>
  import('pages/Entsorgen/InternalEntsorgung/EntsorgenList').then(({ EntsorgenList }) => ({
    default: EntsorgenList
  }))
);
const Logbuch = React.lazy(() =>
  import('pages/Entsorgen/InternalEntsorgung/Logbuch').then(({ Logbuch }) => ({
    default: Logbuch
  }))
);

const AcceptedDeliveriesList = React.lazy(() =>
  import('pages/Lager/AcceptedDeliveries/AcceptedDeliveriesList').then(
    ({ AcceptedDeliveriesList }) => ({
      default: AcceptedDeliveriesList
    })
  )
);

const KorrekturAnnahme = React.lazy(() =>
  import('pages/Lager/KorrekturAnnahme/KorrekturAnnahme').then(({ KorrekturAnnahme }) => ({
    default: KorrekturAnnahme
  }))
);

const AbrufeFurAnfallstellen = React.lazy(() =>
  import('pages/Abrufe/FurAnfallstellen').then(({ FurAnfallstellen }) => ({
    default: FurAnfallstellen
  }))
);

const AbrufeInternalBilling = React.lazy(() =>
  import('pages/Abrufe/InternalBilling').then(({ InternalBilling }) => ({
    default: InternalBilling
  }))
);
const VentoMeineDocument = React.lazy(() =>
  import('pages/eVento/MeineDokumente').then(({ MeineDokumente }) => ({
    default: MeineDokumente
  }))
);
const EntsorgungsaktenList = React.lazy(() =>
  import('pages/MasterData/Entsorgungsakten/EntsorgungsaktenList').then(
    ({ EntsorgungsaktenList }) => ({
      default: EntsorgungsaktenList
    })
  )
);

const GeloschteDokumente = React.lazy(() =>
  import('pages/eVento/GeloschteDokumente').then(({ GeloschteDokumente }) => ({
    default: GeloschteDokumente
  }))
);

const AdvancedTable = React.lazy(() =>
  import('pages/MasterData/Organization/Disposer/ListDisposerv2').then(({ ListDisposerv2 }) => ({
    default: ListDisposerv2
  }))
);
const SammelentsorgungsnachweisePlanner = React.lazy(() =>
  import('pages/eVento/Sammelentsorgungsnachweise/SammelentsorgungsnachweisePlanner').then(
    ({ SammelentsorgungsnachweisePlanner }) => ({
      default: SammelentsorgungsnachweisePlanner
    })
  )
);

const AgsBescheidPlanner = React.lazy(() =>
  import('pages/eVento/SonstigeDocument/AgsBescheid/AgsBescheidPlanner').then(
    ({ AgsBescheidPlanner }) => ({
      default: AgsBescheidPlanner
    })
  )
);

const ExternalEntsorgenLogbuch = React.lazy(() =>
  import('pages/Entsorgen/ExternalEntsorgen/Logbuch').then(({ Logbuch }) => ({
    default: Logbuch
  }))
);

const ExternalEntsorgenVorgangEdit = React.lazy(() =>
  import('pages/Entsorgen/ExternalEntsorgen/VorgangBearbeiten').then(({ VorgangBearbeiten }) => ({
    default: VorgangBearbeiten
  }))
);

const ExternalEntsorgenLogbuchDelete = React.lazy(() =>
  import('pages/Entsorgen/ExternalEntsorgen/DeleteLogbuch/DeleteLogbuch').then(
    ({ DeleteLogbuch }) => ({
      default: DeleteLogbuch
    })
  )
);

const BegleitscheineList = React.lazy(() =>
  import('pages/eVento/Begleitscheine/BegleitscheineList').then(({ BegleitscheineList }) => ({
    default: BegleitscheineList
  }))
);

const EventoNachweiseListView = React.lazy(() =>
  import('pages/eVento/EntsorgungNachweiseList').then(({ EntsorgungnachweiseList }) => ({
    default: EntsorgungnachweiseList
  }))
);

const DokumentBrowser = React.lazy(() =>
  import('pages/eVento/DokumentBrowser').then(({ DokumentBrowser }) => ({
    default: DokumentBrowser
  }))
);
const EventoMainScreen = React.lazy(() =>
  import('pages/eVento/EventoMainScreen').then(({ EventoMainScreen }) => ({
    default: EventoMainScreen
  }))
);

const EntsorgungsnachweiseEditPage = React.lazy(() =>
  import('pages/eVento/EntsorgungNachweiseEdit').then(({ EntsorgungNachweiseEdit }) => ({
    default: EntsorgungNachweiseEdit
  }))
);

const ExternalEntsorgenNachMenge = React.lazy(() =>
  import('pages/Entsorgen/ExternalEntsorgen/NachMengeMain').then(({ NachMengeMain }) => ({
    default: NachMengeMain
  }))
);

const ExternalEntsorgenList = React.lazy(() =>
  import('pages/Entsorgen/ExternalEntsorgen/ExternalEntsorgenList').then(
    ({ ExternalEntsorgenList }) => ({
      default: ExternalEntsorgenList
    })
  )
);

const AbrufeFurErzeuger = React.lazy(() =>
  import('pages/Abrufe/FurErzeuger').then(({ FurErzeuger }) => ({
    default: FurErzeuger
  }))
);

const AbrufeFurSammler = React.lazy(() =>
  import('pages/Abrufe/FurSammler').then(({ FurSammler }) => ({
    default: FurSammler
  }))
);
const BeendeteAbrufeAnfallstellen = React.lazy(() =>
  import('pages/Abrufe/FurAnfallstellen/BeendeteAbrufeAnfallstellen').then(
    ({ BeendeteAbrufeAnfallstellen }) => ({
      default: BeendeteAbrufeAnfallstellen
    })
  )
);
const BeendeteAbrufeErzeuger = React.lazy(() =>
  import('pages/Abrufe/FurErzeuger/BeendeteAbrufeErzeuger').then(({ BeendeteAbrufeErzeuger }) => ({
    default: BeendeteAbrufeErzeuger
  }))
);
const BeendeteAbrufeSammler = React.lazy(() =>
  import('pages/Abrufe/FurSammler/BeendeteAbrufeSammler').then(({ BeendeteAbrufeSammler }) => ({
    default: BeendeteAbrufeSammler
  }))
);
const BGSNrAnforderungen = React.lazy(() =>
  import('pages/eVento/BGSNrAnforderungen').then(({ BGSNrAnforderungen }) => ({
    default: BGSNrAnforderungen
  }))
);
const BGSNrZuteilung = React.lazy(() =>
  import('pages/eVento/SonstigeDocument/BGSNrZuteilung').then(({ BGSNrZuteilung }) => ({
    default: BGSNrZuteilung
  }))
);
const Konfliktdokumente = React.lazy(() =>
  import('pages/eVento/KonfliktdikumenteList').then(({ Konfliktdokumente }) => ({
    default: Konfliktdokumente
  }))
);

const Mitteilungen = React.lazy(() =>
  import('pages/eVento/Mitteilungen').then(({ MitteilungenList }) => ({
    default: MitteilungenList
  }))
);

const UbernahmescheinEditPage = React.lazy(() =>
  import('pages/eVento/UbernahmescheinEdit').then(({ UbernahmescheinEdit }) => ({
    default: UbernahmescheinEdit
  }))
);

const UbernahmescheinDetailPage = React.lazy(() =>
  import('pages/eVento/UbernahmescheinDetail').then(({ UbernahmescheinDetail }) => ({
    default: UbernahmescheinDetail
  }))
);

const DukumentBrowserUbernahmescheinEditPage = React.lazy(() =>
  import('pages/eVento/UbernahmescheinEdit').then(({ UbernahmescheinEdit }) => ({
    default: UbernahmescheinEdit
  }))
);

const ExternalEntsorgenNachAnleifurung = React.lazy(() =>
  import('pages/Entsorgen/ExternalEntsorgen/NachAnleifurungMain').then(
    ({ NachAnleifurungMain }) => ({
      default: NachAnleifurungMain
    })
  )
);
const BegleitscheineEdit = React.lazy(() =>
  import('pages/eVento/Begleitscheine/BegleitscheineEdit').then(({ BegleitscheineEdit }) => ({
    default: BegleitscheineEdit
  }))
);
const BegleitscheineDetailsPage = React.lazy(() =>
  import('pages/eVento/Begleitscheine/BegleitscheineDetail').then(({ BegleitscheineDetail }) => ({
    default: BegleitscheineDetail
  }))
);
const DaDokumenteList = React.lazy(() =>
  import('pages/eVento/SonstigeDocument/DaDokumente').then(({ DaDokumente }) => ({
    default: DaDokumente
  }))
);

const SonstigeMainScreen = React.lazy(() =>
  import('pages/eVento/SonstigeDocument/SonstigeMainScreen/SonstigeMainScreen').then(
    ({ SonstigeMainScreen }) => ({
      default: SonstigeMainScreen
    })
  )
);

const EFGDocumente = React.lazy(() =>
  import('pages/eVento/SonstigeDocument/EFGDocumente/EFGDocumente').then(({ EFGDocumente }) => ({
    default: EFGDocumente
  }))
);
const MaklerPlannerView = React.lazy(() =>
  import('pages/MasterData/Organization/Makler/MaklerPlannerView').then(
    ({ MaklerPlannerView }) => ({
      default: MaklerPlannerView
    })
  )
);
const MaklerCreateNew = React.lazy(() =>
  import('pages/MasterData/Organization/Makler/MaklerCreateNew/MaklerCreateNew').then(
    ({ MaklerCreateNew }) => ({
      default: MaklerCreateNew
    })
  )
);

const ProviderPlannerView = React.lazy(() =>
  import('pages/MasterData/Organization/Provider/ProviderPlannerView').then(
    ({ ProviderPlannerView }) => ({
      default: ProviderPlannerView
    })
  )
);
const Quittungen = React.lazy(() =>
  import('pages/eVento/SonstigeDocument/Quittungen').then(({ Quittungen }) => ({
    default: Quittungen
  }))
);

const RegistrierungsquittungList = React.lazy(() =>
  import('pages/eVento/SonstigeDocument/Registrierungsquittung/RegistrierungsquittungList').then(
    ({ RegistrierungsquittungList }) => ({
      default: RegistrierungsquittungList
    })
  )
);
const RegisterAnforderungenListPlannerView = React.lazy(() =>
  import('pages/eVento/SonstigeDocument/RegisterAnforderungen').then(
    ({ RegisterAnforderungenListPlannerView }) => ({
      default: RegisterAnforderungenListPlannerView
    })
  )
);
const EditAbrufeAnfallstellen = React.lazy(() =>
  import('pages/Abrufe/EditAbrufeAnfallstellen').then(({ EditAbrufeAnfallstellen }) => ({
    default: EditAbrufeAnfallstellen
  }))
);
const EditAbrufeErzeuger = React.lazy(() =>
  import('pages/Abrufe/EditAbrufeErzeuger').then(({ EditAbrufeErzeuger }) => ({
    default: EditAbrufeErzeuger
  }))
);
const EditAbrufeSammler = React.lazy(() =>
  import('pages/Abrufe/EditAbrufeSammler').then(({ EditAbrufeSammler }) => ({
    default: EditAbrufeSammler
  }))
);

const EventoUbernahmescheineList = React.lazy(() =>
  import('pages/eVento/EventoUbernahmescheineList').then(({ EventoUbernahmescheineList }) => ({
    default: EventoUbernahmescheineList
  }))
);

const EAnvNachweise = React.lazy(() =>
  import('pages/MasterData/Entsorgungsakten/EditEntsorgungsakten/eAnv/eAnvNachweise').then(
    ({ eAnvNachweise }) => ({
      default: eAnvNachweise
    })
  )
);

const RegisterAuzuge = React.lazy(() =>
  import('pages/eVento/SonstigeDocument/RegisterAuszuge').then(({ RegisterAuszuge }) => ({
    default: RegisterAuszuge
  }))
);

const BGSNrAnforderungenEdit = React.lazy(() =>
  import('pages/eVento/BGSNrAnforderungen/BGSNrAnforderungenEdit').then(
    ({ BGSNrAnforderungenEdit }) => ({
      default: BGSNrAnforderungenEdit
    })
  )
);
const DokumentBrowserBGSNrAnforderungenEdit = React.lazy(() =>
  import('pages/eVento/BGSNrAnforderungen/BGSNrAnforderungenEdit').then(
    ({ BGSNrAnforderungenEdit }) => ({
      default: BGSNrAnforderungenEdit
    })
  )
);

const SammelentsorgungsnachweiseEdit = React.lazy(() =>
  import('pages/eVento/Sammelentsorgungsnachweise/SammelentsorgungsnachweiseEdit').then(
    ({ SammelentsorgungsnachweiseEdit }) => ({
      default: SammelentsorgungsnachweiseEdit
    })
  )
);

const NewProvider = React.lazy(() =>
  import('pages/MasterData/Organization/Provider/NewProvider').then(({ NewProvider }) => ({
    default: NewProvider
  }))
);

const SonstigeList = React.lazy(() =>
  import('pages/MasterData/Organization/Sonstige/SonstigeList').then(({ SonstigeList }) => ({
    default: SonstigeList
  }))
);

const SonstigeEdit = React.lazy(() =>
  import('pages/MasterData/Organization/Sonstige/SonstigeEdit').then(({ SonstigeEdit }) => ({
    default: SonstigeEdit
  }))
);
const SonstigeNew = React.lazy(() =>
  import('pages/MasterData/Organization/Sonstige/SonstigeNew').then(({ SonstigeNew }) => ({
    default: SonstigeNew
  }))
);

const NeuErstellen = React.lazy(() =>
  import('pages/NeuErstellen').then(({ NeuErstellen }) => ({
    default: NeuErstellen
  }))
);

const EntsorgungsnachweiseDetail = React.lazy(() =>
  import('pages/eVento/EntsorgungsnachweiseDetail').then(({ EntsorgungsnachweiseDetail }) => ({
    default: EntsorgungsnachweiseDetail
  }))
);

const SammlerEntsorgungsnachweiseDetail = React.lazy(() =>
  import('pages/eVento/Sammelentsorgungsnachweise/SammlerEntsorgungsnachweiseDetail').then(
    ({ SammlerEntsorgungsnachweiseDetail }) => ({
      default: SammlerEntsorgungsnachweiseDetail
    })
  )
);

const ToEditEntsorgungsakten = React.lazy(() =>
  import('pages/MasterData/Entsorgungsakten/EditEntsorgungsakten/EditEntsorgungsakten').then(
    ({ EditEntsorgungsakten }) => ({
      default: EditEntsorgungsakten
    })
  )
);

// const EanvEntsorgungsnachweiseEditPage = React.lazy(() =>
//   import('pages/MasterData/Entsorgungsakten/EditEntsorgungsakten/eAnv/eAnvNachweise').then(
//     ({ eAnvNachweise }) => ({
//       default: eAnvNachweise
//     })
//   )
// );

function AppRoutes() {
  const routesArray = [
    {
      path: '/',
      element: <PublicLayout />,
      children: [
        {
          path: routes.login.path,
          element: <Login />
        },
        {
          path: '',
          element: <Navigate to={routes.login.path} />
        },
        {
          path: '*',
          element: <NotFound />
        }
      ]
    },
    {
      path: '/',
      element: <SecuredLayout />,
      children: [
        {
          path: '/',
          element: <SidebarLayout />,
          children: [
            {
              path: routes.users.path,
              element: <UserList />
            },
            {
              path: routes.addUser.path,
              element: <AddUser />
            },
            {
              path: routes.roles.path,
              element: <Roles />
            },
            {
              path: routes.addRole.path,
              element: <AddRole />
            },
            {
              path: routes.locations.path,
              element: <Locations />
            },
            {
              path: routes.addLocationTab.path,
              element: <AddLocationTab />
            },
            {
              path: routes.adminLanding.path,
              element: <AdminLanding />
            },
            {
              path: routes.internalCostAllocation.path,
              element: <Landing />
            },
            {
              path: routes.clearRequest.path,
              element: <ClearReqLanding />
            },
            {
              path: routes.transporter.path,
              element: <PlannerView />
            },
            {
              path: routes.createMakler.path,
              element: <MaklerCreateNew />
            },
            {
              path: routes.makler.path,
              element: <MaklerPlannerView />
            },
            {
              path: routes.provider.path,
              element: <ProviderPlannerView />
            },
            {
              path: routes.notifications.path,
              element: <NotificationList />
            },
            {
              path: routes.documentCheckout.path,
              element: <DocumentCheckout />
            },
            {
              path: routes.newAbrufeAnfallstellen.path,
              element: <CreateAbrufeAnfallstellen />
            },
            {
              path: routes.newAbrufeErzeuger.path,
              element: <CreateAbrufeErzeuger />
            },
            {
              path: routes.newAbrufeSammler.path,
              element: <CreateAbrufeSammler />
            },
            {
              path: routes.createNotification.path,
              element: <CreateNotification />
            },

            {
              path: routes.addProducerOrigin.path,
              element: <AddProducerOrigin />
            },
            { path: routes.addTrasporter.path, element: <NewPlanner /> },
            {
              path: routes.listProducerOrigin.path,
              element: <ListProducerOrigin />
            },
            {
              path: routes.addProducer.path,
              element: <CreateNewProducer />
            },
            {
              path: routes.listProducer.path,
              element: <ProducerList />
            },
            {
              path: routes.listProducerOrigin.path,
              element: <ListProducerOrigin />
            },
            {
              path: routes.addCorporation.path,
              element: <NewCorporation />
            },
            {
              path: routes.listCorporation.path,
              element: <CorporationList />
            },
            {
              path: routes.storageList.path,
              element: <StorageList />
            },
            {
              path: routes.addNewStorage.path,
              element: <NewStorage />
            },
            {
              path: routes.disposer.path,
              element: <ListDisposer />
            },
            {
              path: routes.addDisposer.path,
              element: <AddDisposer />
            },
            {
              path: routes.allOrganizationalUnits.path,
              element: <AllOrganizationalUnits />
            },
            {
              path: routes.OSCI.path,
              element: <OSCI />
            },
            {
              path: routes.abfallerzeugungen.path,
              element: <Abfallerzeugungen />
            },
            {
              path: routes.furAnfallstellen.path,
              element: <FurAnfallstellen />
            },
            {
              path: routes.listLeistungsarten.path,
              element: <LeistungsartenList />
            },
            {
              path: routes.wasteOrigin.path,
              element: <ListWasteOrigin />
            },
            {
              path: routes.packageForms.path,
              element: <ListPackageForms />
            },
            {
              path: routes.addPackageForm.path,
              element: <AddPackageForm />
            },
            {
              path: routes.catalogue.path,
              element: <CatalogueLandingScreen />
            },
            {
              path: routes.disposalRoutes.path,
              element: <DisposalRoutesLandingScreen />
            },
            {
              path: routes.pointOfOrigin.path,
              element: <PointOfOrigin />
            },
            {
              path: routes.stammdaten.path,
              element: <StammdatenMainScreen />
            },

            {
              path: routes.zumAnfallstallen.path,
              element: <WasteGeneration />
            },
            {
              path: routes.zurAnfallstallen.path,
              element: <WasteGenerationZurAnfallstelle />
            },

            {
              path: routes.erzeugerList.path,
              element: <ErzeugerList />
            },
            {
              path: routes.listAvvKatalog.path,
              element: <AvvKatalogList />
            },
            {
              path: routes.addWasteOrigin.path,
              element: <AddWasteSource />
            },
            {
              path: routes.listTemporaryStorage.path,
              element: <TemporaryStorageList />
            },
            {
              path: routes.addTemporaryStorage.path,
              element: <NewTemporaryStorage />
            },
            {
              path: routes.collectorToDisposer.path,
              element: <CollectorToDisposer />
            },
            {
              path: routes.producerToDisposer.path,
              element: <ProducerToDisposer />
            },
            {
              path: routes.furSammlerList.path,
              element: <FurSammlerList />
            },
            {
              path: routes.listLeistungsarten.path,
              element: <LeistungsartenList />
            },
            {
              path: routes.addLeistungsarten.path,
              element: <AddLeistungsarten />
            },
            {
              path: routes.zumErzeuger.path,
              element: <ZumErzeuger />
            },
            {
              path: routes.zumAbfallstamm.path,
              element: <ZumAbfallstamm />
            },
            {
              path: routes.listTeilnehmer.path,
              element: <ListTeilNehmer />
            },
            {
              path: routes.listCostCenter.path,
              element: <CostCenterList />
            },

            {
              path: routes.autodispositionen.path,
              element: <Autodispositionen />
            },
            {
              path: routes.addNewCostCenter.path,
              element: <CostCenterAdd />
            },
            {
              path: routes.eVentoLanding.path,
              element: <EventoMainScreen />
            },
            {
              path: routes.sammlerZumAbfallstamm.path,
              element: <SammlerAbfallstamm />
            },
            {
              path: routes.annahmeList.path,
              element: <AnnahmeList />
            },
            {
              path: routes.sammlerZumSammler.path,
              element: <SammlerZumSammler />
            },
            {
              path: routes.individualReportsList.path,
              element: <IndividualReportsList />
            },
            {
              path: routes.individualReportsAdd.path,
              element: <IndividualReportsAdd />
            },
            {
              path: routes.lagerList.path,
              element: <LagerList />
            },
            {
              path: routes.dispoList.path,
              element: <DispoList />
            },
            {
              path: routes.ausEntrosgungsweg.path,
              element: <CreateNewDisposalFile />
            },
            {
              path: routes.ausEntrosgungsweg.path,
              element: <CreateNewDisposalFile />
            },
            {
              path: routes.entsorgenHome.path,
              element: <EntsorgenLandingPage />
            },
            {
              path: routes.internalEntsorgerList.path,
              element: <EntsorgenList />
            },
            {
              path: routes.logbuch.path,
              element: <Logbuch />
            },
            {
              path: routes.acceptedDeliveries.path,
              element: <AcceptedDeliveriesList />
            },

            { path: routes.abrufeFurAnfallstellen.path, element: <AbrufeFurAnfallstellen /> },
            {
              path: routes.abrufeInternalBilling.path,
              element: <AbrufeInternalBilling />
            },
            {
              path: routes.eVentoMeineDokumente.path,
              element: <VentoMeineDocument />
            },
            {
              path: routes.geloschteDokumente.path,
              element: <GeloschteDokumente />
            },
            {
              path: routes.advancesTableLink.path,
              element: <AdvancedTable />
            },
            {
              path: routes.ExternalLogbuch.path,
              element: <ExternalEntsorgenLogbuch />
            },
            {
              path: routes.entsorgungsaktenList.path,
              element: <EntsorgungsaktenList />
            },
            {
              path: routes.begleitscheineList.path,
              element: <BegleitscheineList />
            },
            {
              path: routes.sammelentsorgungsnachweiseList.path,
              element: <SammelentsorgungsnachweisePlanner />
            },
            {
              path: routes.agsBescheidList.path,
              element: <AgsBescheidPlanner />
            },
            {
              path: routes.eVentoNachweiseListView.path,
              element: <EventoNachweiseListView />
            },
            {
              path: routes.dokumentBrowser.path,
              element: <DokumentBrowser />
            },
            {
              path: routes.eVentoLanding.path,
              element: <EventoMainScreen />
            },

            {
              path: routes.externalEntsorgenList.path,
              element: <ExternalEntsorgenList />
            },
            {
              path: routes.abrufeFurErzeuger.path,
              element: <AbrufeFurErzeuger />
            },
            {
              path: routes.abrufeFurSammler.path,
              element: <AbrufeFurSammler />
            },
            {
              path: routes.beendeteAbrufeAnfallstellen.path,
              element: <BeendeteAbrufeAnfallstellen />
            },
            {
              path: routes.beendeteAbrufeErzeuger.path,
              element: <BeendeteAbrufeErzeuger />
            },
            {
              path: routes.beendeteAbrufeSammler.path,
              element: <BeendeteAbrufeSammler />
            },
            {
              path: routes.BGSNrAnforderungenList.path,
              element: <BGSNrAnforderungen />
            },
            {
              path: routes.BGSNrZuteilung.path,
              element: <BGSNrZuteilung />
            },
            {
              path: routes.konfliktdikumenteList.path,
              element: <Konfliktdokumente />
            },
            {
              path: routes.mitteilungenList.path,
              element: <Mitteilungen />
            },
            {
              path: routes.daDokumenteList.path,
              element: <DaDokumenteList />
            },
            {
              path: routes.sonstigemainscreen.path,
              element: <SonstigeMainScreen />
            },
            {
              path: routes.efgdocumente.path,
              element: <EFGDocumente />
            },
            {
              path: routes.quittungenDataList.path,
              element: <Quittungen />
            },
            {
              path: routes.registrierungsquittungList.path,
              element: <RegistrierungsquittungList />
            },
            {
              path: routes.eventoUbernahmescheineList.path,
              element: <EventoUbernahmescheineList />
            },
            {
              path: routes.RegisterAuszuge.path,
              element: <RegisterAuzuge />
            },
            {
              path: routes.registerAnforderungenListPlannerView.path,
              element: <RegisterAnforderungenListPlannerView />
            },
            {
              path: routes.newProvider.path,
              element: <NewProvider />
            },
            {
              path: routes.listSonstige.path,
              element: <SonstigeList />
            },

            {
              path: routes.newSonstige.path,
              element: <SonstigeNew />
            }
          ]
        },
        {
          path: '/',
          element: <PlainLayout />,
          children: [
            {
              path: routes.home.path,
              element: <Home />
            },
            {
              path: routes.changeHistory.path,
              element: <ChangeHistory />
            },
            {
              path: routes.editUser.path,
              element: <EditUser />
            },
            {
              path: routes.userauthorize.path,
              element: <UserAuthorize />
            },
            {
              path: routes.passwordmgmt.path,
              element: <Passwordmgmt />
            },
            {
              path: routes.editRole.path,
              element: <EditRole />
            },
            {
              path: routes.editWasteOrigin.path,
              element: <EditWasteOrigin />
            },
            {
              path: routes.editLocationTab.path,
              element: <EditLocationTab />
            },
            {
              path: routes.sampleForm.path,
              element: <SampleForm />
            },
            {
              path: routes.sampleTabs.path,
              element: <Tabhome />
            },
            {
              path: routes.allOrganizationalUnits.path,
              element: <AllOrganizationalUnits />
            },
            {
              path: routes.editPackageForm.path,
              element: <EditPackageForm />
            },
            {
              path: routes.editProducerOrigin.path,
              element: <EditProducerOrigin />
            },
            {
              path: routes.editProducer.path,
              element: <ProducerEdit />
            },
            {
              path: routes.editProducerOrigin.path,
              element: <EditProducerOrigin />
            },
            {
              path: routes.editCorporation.path,
              element: <CorporationEdit />
            },
            {
              path: routes.editStorage.path,
              element: <EditStorage />
            },
            {
              path: routes.editDisposer.path,
              element: <EditDisposer />
            },
            {
              path: routes.editFurAnfallstellen.path,
              element: <FurAnfallstellenEdit />
            },
            {
              path: routes.editTrasporter.path,
              element: <PlannerEdit />
            },
            {
              path: routes.editMakler.path,
              element: <MaklerPlannerEdit />
            },
            {
              path: routes.editSonstige.path,
              element: <SonstigeEdit />
            },
            {
              path: routes.editProvider.path,
              element: <ProviderPlannerEdit />
            },
            {
              path: routes.erzeugerEdit.path,
              element: <ErzeugerEdit />
            },
            {
              path: routes.editTemporaryStorage.path,
              element: <TemporaryStorageEdit />
            },
            {
              path: routes.editLeistungsarten.path,
              element: <EditLeistungsarten />
            },
            {
              path: routes.changepassword.path,
              element: <ChangePassword />
            },
            {
              path: routes.editTeilnehmer.path,
              element: <EditTeilNehmer />
            },

            {
              path: routes.lagerOrganisieren.path,
              element: <Organisieren />
            },
            {
              path: routes.NachAnlieferung.path,
              element: <InternalEntsorgungNachAnlieferung />
            },
            { path: routes.NachMenge.path, element: <InternalEntsorgungNachMenge /> },
            {
              path: routes.editNotification.path,
              element: <EditNotification />
            },
            {
              path: routes.dispoEdit.path,
              element: <DispoEdit />
            },
            {
              path: routes.editEntsorgungsakten.path,
              element: <EditEntsorgungsakten />
            },
            {
              path: routes.korrekturAnnahme.path,
              element: <KorrekturAnnahme />
            },
            {
              path: routes.entsorgungsnachweiseEdit.path,
              element: <EntsorgungsnachweiseEditPage />
            },
            {
              path: routes.ExternalVorgangEdit.path,
              element: <ExternalEntsorgenVorgangEdit />
            },
            {
              path: routes.ExternalEntsorgenLogbuchDelete.path,
              element: <ExternalEntsorgenLogbuchDelete />
            },
            {
              path: routes.externalEntsorgenNachMenge.path,
              element: <ExternalEntsorgenNachMenge />
            },
            {
              path: routes.ubernahmescheinEdit.path,
              element: <UbernahmescheinEditPage />
            },
            {
              path: routes.externalEntsorgenNachAnlieferungen.path,
              element: <ExternalEntsorgenNachAnleifurung />
            },
            {
              path: routes.begleitscheineEdit.path,
              element: <BegleitscheineEdit />
            },
            {
              path: routes.editAbrufeAnfallstellen.path,
              element: <EditAbrufeAnfallstellen />
            },
            {
              path: routes.editAbrufeErzeuger.path,
              element: <EditAbrufeErzeuger />
            },
            {
              path: routes.editAbrufeSammler.path,
              element: <EditAbrufeSammler />
            },
            {
              path: routes.eAnvNachweise.path,
              element: <EAnvNachweise />
            },
            {
              path: routes.externalVorgangEditUpload.path,
              element: <ExternalEntsorgenVorgangEdit />
            },
            {
              path: routes.bGSNrAnforderungenEdit.path,
              element: <BGSNrAnforderungenEdit />
            },
            {
              path: routes.sammelentsorgungsnachweiseEdit.path,
              element: <SammelentsorgungsnachweiseEdit />
            },
            {
              path: routes.ubernahmescheinDetail.path,
              element: <UbernahmescheinDetailPage />
            },
            {
              path: routes.begleitscheineDetail.path,
              element: <BegleitscheineDetailsPage />
            },
            {
              path: routes.furSammlerEdit.path,
              element: <FurSammlerEdit />
            },
            {
              path: routes.editCostCenter.path,
              element: <CostCenterEdit />
            },
            {
              path: routes.individualReportsEdit.path,
              element: <IndividualReportsEdit />
            },
            {
              path: routes.konfliktDokumenteBegleitscheineEdit.path,
              element: <BegleitscheineEdit />
            },
            {
              path: routes.konfliktDokumenteSammelentsorgungsnachweiseEdit.path,
              element: <SammelentsorgungsnachweiseEdit />
            },
            {
              path: routes.konfliktDokumenteUbernahmescheinEdit.path,
              element: <UbernahmescheinEditPage />
            },
            {
              path: routes.konfliktDokumenteEntsorgungsnachweiseEdit.path,
              element: <EntsorgungsnachweiseEditPage />
            },
            {
              path: routes.dokumentBrowserUnsEdit.path,
              element: <DukumentBrowserUbernahmescheinEditPage />
            },
            {
              path: routes.bGSNrAnforderungenUNSEdit.path,
              element: <DokumentBrowserBGSNrAnforderungenEdit />
            },
            {
              path: routes.neuErstellen.path,
              element: <NeuErstellen />
            },
            {
              path: routes.mitteilungenNeuErstellen.path,
              element: <NeuErstellen />
            },
            {
              path: routes.entsorgungsnachweiseDetailPage.path,
              element: <EntsorgungsnachweiseDetail />
            },
            {
              path: routes.fromeANVentsorgungsnachweiseEdit.path,
              element: <EntsorgungsnachweiseEditPage />
            },
            {
              path: routes.fromeANVSammlerEntsorgungsnachweiseEdit.path,
              element: <SammelentsorgungsnachweiseEdit />
            },
            {
              path: routes.toeANVentsorgungsnachweiseEdit.path,
              element: <ToEditEntsorgungsakten />
            },
            {
              path: routes.sammlerEntsorgungsnachweiseDetailPage.path,
              element: <SammlerEntsorgungsnachweiseDetail />
            },
            {
              path: routes.fromEntsorgungsnachweiseDetail.path,
              element: <EntsorgungsnachweiseDetail />
            },
            {
              path: routes.toEntsorgungsnachweiseDetail.path,
              element: <EntsorgungsnachweiseDetail />
            },
            {
              path: routes.toeAnvEdit.path,
              element: <EntsorgungsnachweiseEditPage />
            },
            {
              path: routes.fromSammlerEntsorgungsnachweiseDetail.path,
              element: <SammlerEntsorgungsnachweiseDetail />
            },
            {
              path: routes.toSammlerEntsorgungsnachweiseDetail.path,
              element: <SammlerEntsorgungsnachweiseDetail />
            }
          ]
        },
        {
          path: '/',
          element: <NotificationLayout />,
          children: [
            {
              path: routes.abrufeLanding.path,
              element: <AbrufeLanding />
            }
          ]
        },
        { path: '', element: <Navigate to='home' /> }
      ]
    }
  ];
  return (
    <React.Suspense fallback={<>Warten Sie mal...</>}>{useRoutes(routesArray)}</React.Suspense>
  );
}
export default AppRoutes;
