export const STORAGE_USER_INFO = 'user-info';
export const STORAGE_ACCESS_TOKEN = 'access-token';
export const STORAGE_REFRESH_TOKEN = 'refresh-token';
export const STORAGE_USER_LOCATION = 'user-location';
export const STORAGE_USER_LOCATION_KEY = 'user-location-key';
export const STORAGE_USER_PERMISSIONS = 'user-permissions';
export const LOGIN_TYPE = 'login-type';
export const SSO_LOGIN = 'sso';
export const CRED_LOGIN = 'cred';
export const STORAGE_USERNAME = 'user-name';
