import axios from 'axios';
import { getFromStorage } from 'core/utils/utils.helper';
import { STORAGE_USER_LOCATION_KEY } from 'constants/storage';
import 'core/api/httpInterceptors';

/**
 * Common method through which all API requests are passed
 * @param endPoint
 * @param method
 * @param data
 * @returns
 */
export function httpRequest(endPoint: any, method: string, data?: any) {
  const isMock = endPoint && endPoint.isMock ? endPoint.isMock : false;
  const requestMethod = isMock ? 'get' : method;
  const urlObj: any = JSON.parse(process.env.REACT_APP_API_URL || '');
  const locationKey = getFromStorage(STORAGE_USER_LOCATION_KEY) || 'be';
  // choose between mock or api url
  const baseUrl = isMock ? process.env.REACT_APP_MOCK_API_URL : urlObj[locationKey];
  const requestUrl = endPoint && endPoint.url ? baseUrl + endPoint.url : baseUrl;
  switch (requestMethod) {
    case 'get':
      return get(requestUrl);
    case 'post':
      return post(requestUrl, data);
    case 'put':
      return put(requestUrl, data);
    case 'postUrlencoded':
      return postUrlencoded(requestUrl, data);
  }
}

function get(url: string) {
  return axios.get(url);
}

function post(url: string, data: any) {
  return axios.post(url, data);
}

function postUrlencoded(url: string, data: any) {
  const dataObj = Object.keys(data)
    .map(key => `${key}=${encodeURIComponent(data[key])}`)
    .join('&');
  return axios.post(url, dataObj);
}

function put(url: string, data: any) {
  return axios.post(url, data);
}
