import React from 'react';
import { Outlet } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Sidebar } from 'components/Sidebar';
import './SidebarLayout.scss';

export function SidebarLayout() {
  return (
    <Grid container className='sidebar-layout'>
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <Sidebar />
      </Grid>
      <Grid item xs={12} sm={12} md={10} lg={10} className='main-content'>
        <Outlet />
      </Grid>
    </Grid>
  );
}
