import React from 'react';
import { Outlet } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import './PlainLayout.scss';

export function PlainLayout() {
  return (
    <Grid container className='plain-layout'>
      <Grid item xs={12} sm={12} md={12} lg={12} className='main-content'>
        <Outlet />
      </Grid>
    </Grid>
  );
}
