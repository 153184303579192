const blstateOptions = [
  {
    label: 'Baden Württemberg',
    value: {
      label: 'H',
      value: '[H] Baden Württemberg'
    }
  },
  {
    label: 'Bayern',
    value: {
      label: 'I',
      value: '[I] Bayern'
    }
  },
  {
    label: 'Berlin',
    value: {
      label: 'L',
      value: '[L] Berlin'
    }
  },
  {
    label: 'Brandenburg',
    value: {
      label: 'P',
      value: '[P] Brandenburg'
    }
  },
  {
    label: 'Bremen',
    value: {
      label: 'D',
      value: '[D] Bremen'
    }
  },
  {
    label: 'Hamburg',
    value: {
      label: 'B',
      value: '[B] Hamburg'
    }
  },
  {
    label: 'Hessen',
    value: {
      label: 'F',
      value: '[F] Hessen'
    }
  },
  {
    label: 'Mecklenburg-Vorpommern',
    value: {
      label: 'M',
      value: '[M] Mecklenburg-Vorpommern'
    }
  },
  {
    label: 'Niedersachsen',
    value: {
      label: 'C',
      value: '[C] Niedersachsen'
    }
  },
  {
    label: 'Nordrhein-Westfalen',
    value: {
      label: 'E',
      value: '[E] Nordrhein-Westfalen'
    }
  },
  {
    label: 'Rheinland-Pfalz',
    value: {
      label: 'G',
      value: '[G] Rheinland-Pfalz'
    }
  },
  {
    label: 'Saarland',
    value: {
      label: 'K',
      value: '[K] Saarland'
    }
  },
  {
    label: 'Sachsen',
    value: {
      label: 'S',
      value: '[S] Sachsen'
    }
  },
  {
    label: 'Sachsen-Anhalt',
    value: {
      label: 'N',
      value: '[N] Sachsen-Anhalt'
    }
  },
  {
    label: 'Schleswig-Holstein',
    value: {
      label: 'A',
      value: '[A] Schleswig-Holstein'
    }
  },
  {
    label: 'Thüringen',
    value: {
      label: 'R',
      value: '[R] Thüringen'
    }
  }
];

const getStateConfig = (formData: any = null) => {
  return {
    blstate: {
      type: 'dropdown',
      id: 'blstate',
      label: '',
      placeholder: '',
      value: formData.blstate ? formData.blstate : '',
      parentClass: '',

      options: blstateOptions.map((item: any) => {
        return {
          label: item.label,
          value: {
            label: item.value.label,
            value: item.value.value
          }
        };
      })
    }
  };
};

const getKriesBLStateConfig = (formData: any = null) => {
  return {
    kreis: {
      type: 'text',
      id: 'Kreis',
      label: 'Kreis',
      placeholder: '',
      tooltip: '',
      parentClass: '',
      value: formData.Kreis ? formData.Kreis : ''
    },

    kreiskennung: {
      type: 'text',
      id: 'kreiskennung',
      label: 'kreiskennung',
      placeholder: '',
      tooltip: '',
      parentClass: '',
      value: formData.kreiskennung ? formData.kreiskennung : ''
    }
  };
};
export { getKriesBLStateConfig, getStateConfig, blstateOptions };
