import React from 'react';
import AppRoutes from 'core/routing/AppRoutes';
import ErrorBoundary from 'core/error-handling/ErrorBoundary';
import { Loader } from 'components/Loader';
import './App.scss';

function App() {
  return (
    <ErrorBoundary>
      <AppRoutes />
      <Loader />
    </ErrorBoundary>
  );
}

export default App;
