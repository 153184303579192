import { format } from 'date-fns';
import { DATE_FORMAT_CLIENT } from 'constants/date-time';
import { CHECKBOX_CHECKED, CHECKBOX_INDETERMINATE, CHECKBOX_UNCHECKED } from 'constants/form';
import { blstateOptions } from 'helpers/egfBundesLand.helper';
// import { createJSDocEnumTag } from 'typescript';

// select the browser storage
const appStorage = sessionStorage; //localStorage;

const cacheStorage = localStorage;


/**
 * Get a value from browser storage
 * @param key
 * @returns string | null
 */
const getFromStorage = (key: string) => {
  return key && appStorage.getItem(key) ? appStorage.getItem(key) : null;
};

/**
 * Set a value to browser storage using a specified key
 * @param key
 * @param value
 */
const setToStorage = (key: string, value = '') => {
  if (key) {
    appStorage.setItem(key, value);
  }
};


/**
 * Get a value from browser storage
 * @param key
 * @returns string | null
 */
 const getFromLocalStorage = (key: string) => {
  return key && cacheStorage.getItem(key) ? cacheStorage.getItem(key) : null;
};

/**
 * Set a value to browser storage using a specified key
 * @param key
 * @param value
 */
const setToLocalStorage = (key: string, value = '') => {
  if (key) {
    cacheStorage.setItem(key, value);
  }
};

/**
 * Remove a specific value or clear the entire browser storage
 * @param key
 */
const removeFromStorage = (key = '') => {
  if (key) {
    appStorage.removeItem(key);
  } else {
    appStorage.clear();
  }
};



/**
 * Remove a specific value or clear the entire browser storage
 * @param key
 */
 const removeFromLocalStorage = (key = '') => {
  if (key) {
    cacheStorage.removeItem(key);
  } else {
    cacheStorage.clear();
  }
};

/**
 * Format given date
 * @param date
 * @param dateFormat
 * @returns
 */
const formatDateTime = (date = new Date(), dateFormat = DATE_FORMAT_CLIENT) => {
  return format(date, dateFormat);
};

/**
 * Get time from date object in AM/PM format
 * @param date
 * @returns
 */
const formatAMPM = (dateObj: any) => {
  const twelve = 12;
  const ten = 10;
  const date = new Date(dateObj);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  // hours = hours+1;
  const ampm = hours >= twelve ? 'PM' : 'AM';

  hours = hours % twelve;
  hours = hours ? hours : twelve; // the hour '0' should be '12'
  let minutes1 = minutes < ten ? '0' + minutes : minutes;
  let strTime = hours + ':' + minutes1 + ' ' + ampm;
  return strTime;
};
/**
 * format date and time; date in the format '2022-02-01T07:47:43'
 * @param date date value
 * @param isTimeshow result want to format time also; true-format date and time eg:01/02/2022 07:47:43 AM;false-format date only eg:06/01/2022
 * @returns formated date / time
 */
const formatTimeandDate = (date: string, isTimeshow: boolean) => {
  const twelve = 12;
  const ten = 10;
  const zero = 0;
  let newDate = new Date(date);
  if (!isTimeshow) {
    return newDate.toLocaleDateString('fr-CH');
  } else {
    let ampm;
    let hour : any;
    let second;
    let minute;
    if (newDate.getHours() >= twelve) {
      ampm = 'PM';
    } else {
      ampm = 'AM';
    }
    if (newDate.getHours() < ten) {
      if (newDate.getHours() == zero) hour = '12';
      else hour = '0' + newDate.getHours();
    } else {
      hour = newDate.getHours();
    }
    if (newDate.getMinutes() < ten) {
      minute = '0' + newDate.getMinutes();
    } else {
      minute = newDate.getMinutes();
    }
    if (newDate.getSeconds() < ten) {
      second = '0' + newDate.getSeconds();
    } else {
      second = newDate.getSeconds();
    }
    if (newDate.getHours() > twelve) {
      hour = newDate.getHours() - twelve;
    } else {
      if (newDate.getHours() == zero) hour = '12';
      else hour = newDate.getHours();
    }
    if (hour < ten) {
      if (hour == zero) hour = '12';
      else hour = '0' + hour;
    }
    let timeDate = newDate.toLocaleDateString('fr-CH') + ' ' + hour + ':' + minute + ':' + second + ' ' + ampm;
    // let timeDate = newDate.toLocaleDateString('fr-CH') + ' ' + (hour && hour >= 1 ? (hour-1) : hour) + ':' + minute + ':' + second + ' ' + ampm;
    return timeDate;
  }
};
/**
 * Get validCombination with one parameter
 * @param ccNum
 * @returns value with valid combination
 */
const IstValideKombination = (ccNum: string) => {
  let bRet = true;
  let przf = -1;

  if (ccNum && ccNum.trim() == '') {
    bRet = false;
  } else if (ccNum.trim().length < 2) {
    bRet = false;
  } else {
    let sWork: string = ccNum.trim();
    let nLenWork: number = sWork.length;

    try {
      przf = parseInt(sWork.slice(nLenWork - 1));
      let calcPrzf = BerechnePruefziffer(sWork.substring(0, nLenWork - 1));
      bRet = przf == parseInt(calcPrzf);
    } catch {
      bRet = false;
    }
  }
  return bRet;
};
/**
 * IstValideKombinationII
 * @param ccNum
 * @param przf
 * @returns a value with type number
 */
const IstValideKombinationII = (ccNum: string, przf: number) => {
  let calcPrzf: any = BerechnePruefziffer(ccNum);
  return przf == parseInt(calcPrzf);
};
/**
 * BerechnePruefziffer
 * @param ccNum
 * @returns a value with type string
 * to avoid issue when create Zero as output, its treated as boolean
 */
const BerechnePruefziffer = (ccNum: any) => {
  if (ccNum.trim() == '') {
    return '';
  } else {
    let cNumber: any = ccNum.split('');
    let len: number = cNumber.length;

    let nFact: any = [];
    nFact[0] = 1;
    nFact[1] = 5;

    let posfact = 0;
    let nSummeVonQuerSummen = 0;

    for (let ic: any = 0; ic < len; ic++) {
      posfact = ic % 2;
      const product = nFact[posfact] * cNumber[ic].charCodeAt(0);
      nSummeVonQuerSummen += Quersumme(product);
    }

    let mod: number = 9 - (nSummeVonQuerSummen % 10);
    mod = Math.round(mod);
    return mod.toString();
  }
};

const FreistellungPruefziffer = (ccNum: any) => {
  if (ccNum.trim() == '') {
    return '-1';
  } else {
    let cNumber: any = ccNum.split('');
    let len: number = cNumber.length;

    let nFact: any = [];
    nFact[0] = 1;
    nFact[1] = 5;

    let posfact = 0;
    let nSummeVonQuerSummen = 0;

    for (let ic: any = 0; ic < len; ic++) {
      posfact = ic % 2;
      const product = nFact[posfact] * cNumber[ic].charCodeAt(0);
      nSummeVonQuerSummen += Quersumme(product);
    }

    let mod: number = 12 - (nSummeVonQuerSummen % 10);
    mod = Math.round(mod);
    return mod.toString();
  }
};

/**
 * Quersumme
 * @param quotient
 * @returns a value with type number
 */
const Quersumme = (quotient: number) => {
  // let quersumme = 0;
  // let rest = 0;
  // console.log(quotient);
  // while (quotient != 0) {
  //   rest = quotient % 10;
  //   quotient = quotient / 10;
  //   quersumme = quersumme + rest;
  // }
  // console.log(quersumme);
  // console.log('************************************');
  // return quersumme;

  let quersumme = 0;
  if (quotient != 0) {
    const num = quotient;
    const digits = num.toString().split('');
    const realDigits = digits.map(Number);
    const sum = realDigits.reduce((partialSum, a) => partialSum + a, 0);
    quersumme = quersumme + sum;
  }
  return quersumme;
};

const officialNumber = (Num: string) => {
  return Num.slice(0, -1);
};

const controlNumber = (Num: string) => {
  return Num.charAt(Num.length - 1);
};

/**
 * CheckValideBGSNr
 * @param bgsnr_przf
 * @returns a value with type boolean
 */
const CheckValideBGSNr = (bgsnr_przf: string) => {
  let work: string = bgsnr_przf.trim();

  if (work.length != 15) {
    return false;
  } else if (IstValideKombination(bgsnr_przf) == false) {
    return false;
  } else {
    let regex = new RegExp('^([1](d{13}))$');
    if (regex.test(bgsnr_przf.substring(0, 14))) {
      return false;
    } else {
      return true;
    }
  }
};
/**
 * CheckValideUNSNr
 * @param unsnr_przf
 * @returns a value with type boolean
 */
const CheckValideUNSNr = (unsnr_przf: string) => {
  let work: string = unsnr_przf.trim();
  if (work.length != 15) {
    return false;
  } else if (IstValideKombination(unsnr_przf) == false) {
    return false;
  } else {
    let regex = new RegExp('^([2](d{13}))$');
    if (regex.test(unsnr_przf.substring(0, 14))) {
      return false;
    } else {
      return true;
    }
  }
};
/**
 * CheckValideENNr
 * @param ennr_przf
 * @returns a value with type boolean
 */
const CheckValideENNr = (ennr_przf: string) => {
  let work: string = ennr_przf.trim();
  if (work.length != 13) {
    return false;
  } else if (IstValideKombination(ennr_przf) == false) {
    return false;
  } else {
    let regex = new RegExp('^((E|S)N[ABCDEFGHIKLMNPRS](.{9}))$');
    if (regex.test(ennr_przf.substring(0, 14))) {
      return false;
    } else {
      return true;
    }
  }
};
/**
 * CheckValideENNrOhnePruefziffer
 * @param ennr
 * @returns a value with type boolean
 */
const CheckValideENNrOhnePruefziffer = (ennr: string) => {
  let work: string = ennr.trim();
  if (work.length != 12) {
    return false;
  } else {
    let regex = new RegExp('^((E|S)N[ABCDEFGHIKLMNPRS](.{9}))$');
    return regex.test(work);
  }
};
/**
 * CheckValideBGSNrOhnePruefziffer
 * @param bgsnr
 * @returns a value with type boolean
 */
const CheckValideBGSNrOhnePruefziffer = (bgsnr: string) => {
  let work = bgsnr.trim();
  if (work.length != 14) {
    return false;
  } else {
    let regex = new RegExp('^([1](d{13}))$');
    return regex.test(work);
  }
};
/**
 * CheckValideUNSNrOhnePruefziffer
 * @param unsnr
 * @returns a value with type boolean
 */
const CheckValideUNSNrOhnePruefziffer = (unsnr: string) => {
  let work: string = unsnr.trim();
  if (work.length != 14) {
    return false;
  } else {
    let regex = new RegExp('^([2](d{13}))$');
    return regex.test(work);
  }
};
/**
 * CheckValideTeilnehmerNr
 * @param teilnehmerNr_przf
 * @returns a value with type boolean
 */
const CheckValideTeilnehmerNr = (teilnehmerNr_przf: string) => {
  let work: string = teilnehmerNr_przf.trim();
  if (work.length != 10) {
    return false;
  } else if (IstValideKombination(work) == false) {
    return false;
  } else {
    let regex = new RegExp('^([ABCDEFGHIKLMNPRS](.{8}))$');
    if (regex.test(work.substring(0, 9))) {
      return false;
    } else {
      return true;
    }
  }
};
/**
 * CheckValideTeilnehmerNrOhnePruefziffer
 * @param teilnehmerNr
 * @returns a value with type boolean
 */
const CheckValideTeilnehmerNrOhnePruefziffer = (teilnehmerNr: string) => {
  let work: string = teilnehmerNr.trim();
  if (work.length != 9) {
    return false;
  } else {
    let regex = new RegExp('^([ABCDEFGHIKLMNPRS](.{8}))$');
    if (regex.test(work.substring(0, 9))) {
      return true;
    } else {
      return false;
    }
  }
};
/**
 * CheckValideNNNr
 * @param ennr_przf
 * @returns a value with type boolean
 */
const CheckValideNNNr = (ennr_przf: string) => {
  let work: string = ennr_przf.trim();
  if (work.length != 13) {
    return false;
  } else if (IstValideKombination(ennr_przf) == false) {
    return false;
  } else {
    let regex = new RegExp('^((E|S)R[ABCDEFGHIKLMNPRS](.{9}))$');
    if (regex.test(work.substring(0, 12))) {
      return false;
    } else {
      return true;
    }
  }
};
/**
 * TranslateBundesland
 * @param blKEY
 * @returns a value with type string
 */
const TranslateBundesland = (blKEY: string) => {
  let sRet: string = blKEY;

  if (sRet == 'H') {
    return 'Baden Württemberg';
  } else if (sRet == 'I') {
    return 'Bayern';
  } else if (sRet == 'L') {
    return 'Berlin';
  } else if (sRet == 'P') {
    return 'Brandenburg';
  } else if (sRet == 'D') {
    return 'Bremen';
  } else if (sRet == 'B') {
    return 'Hamburg';
  } else if (sRet == 'F') {
    return 'Hessen';
  } else if (sRet == 'M') {
    return 'Mecklenburg-Vorpommern';
  } else if (sRet == 'C') {
    return 'Niedersachsen';
  } else if (sRet == 'E') {
    return 'Nordrhein-Westfalen';
  } else if (sRet == 'G') {
    return 'Rheinland-Pfalz';
  } else if (sRet == 'K') {
    return 'Saarland';
  } else if (sRet == 'S') {
    return 'Sachsen';
  } else if (sRet == 'N') {
    return 'Sachsen-Anhalt';
  } else if (sRet == 'A') {
    return 'Schleswig-Holstein';
  } else if (sRet == 'R') {
    return 'Thüringen';
  } else {
    return `${sRet} unbekannt`;
  }
};
/**
 * TranslateRolle
 * @param rolleKEY
 * @returns a value with type string
 */
const TranslateRolle = (rolleKEY: string) => {
  let sRet: string = rolleKEY.toLowerCase();

  if (sRet == 'erz') {
    return 'Erzeuger';
  } else if (sRet == 'bef') {
    return 'Beförderer/Sammler';
  } else if (sRet == 'bef.1') {
    return '1. Beförderer';
  } else if (sRet == 'bef.2') {
    return '2. Beförderer';
  } else if (sRet == 'bef.3') {
    return '3. Beförderer';
  } else if (sRet == 'zwl') {
    return 'Zwischenlager';
  } else if (sRet == 'ent') {
    return 'Entsorger';
  } else if (sRet == 'beh') {
    return 'Behörde';
  } else if (sRet == 'mak') {
    return 'Makler';
  } else if (sRet == 'beverz' || sRet == 'bve') {
    return 'Bevollmächtigter des Erzeugers';
  } else if (sRet == 'prov' || sRet == 'prv') {
    return 'Provider';
  } else if (sRet == 'sonst' || sRet == 'snt') {
    return 'Sonstige';
  } else {
    return rolleKEY;
  }
};

/**
 * Split last character from string and return with space
 * @param key
 * @returns a value with type string
 */
const SplitLastCharacter = (key: string) => {
  let lastChar = key.substr(key.length - 1);
  let initial = key.slice(0, -1);
  return `${initial} ${lastChar}`;
};

/**
 * Split last character from string and return array
 * @param key
 * @returns a value with type string
 */
const SplitLastCharacterAsArray = (key: string) => {
  let lastChar = key.substr(key.length - 1);
  let initial = key.slice(0, -1).trim();
  return [initial, lastChar];
};

/**
 * Returns date in YYYY-MM-DD
 * @param date
 * @returns current date
 */
const formatDateToApi = (date: string) => {
  let newDate = new Date(date);
  return newDate.toLocaleDateString('fr-CA');
};

const formatNewDate = (date: string) => {
  let newDate = new Date(date);
  let timeDate = newDate.toLocaleDateString('fr-CH');
  return timeDate;
};

/**
 *
 * @param fileUrl the url path of the file that get in the response
 * @param fileName name for the downloading file
 */
const downloadAttachment = (fileUrl: any, fileName: any) => {
  fetch(fileUrl, {
    method: 'GET'
  })
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName; // the filename you want
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
};

const quantityFormatter = (data: any) => {
  if (data == null || '' || undefined) {
    return 0;
  } else {
    let num = Number(data);
    let qty = num.toFixed(3);

    return Number(qty) * 1000;
  }
};

const getActivityUnit = (data: any) => {
  let unit = 'H';
  if (data == '509532') unit = 'H';
  else if (data == '509535') unit = 'KG';
  else if (data == '509537') unit = 'KG';
  else if (data == '509538') unit = 'KG';
  else if (data == '509540') unit = 'KG';
  else if (data == '509620') unit = 'H';
  else if (data == '509522') unit = 'H';
  else if (data == '509555') unit = 'H';
  else if (data == '509623') unit = 'KG';
  else if (data == '509531') unit = 'H';
  else unit = 'H';

  return unit;
};
const getCostsenterNew = (data: any, checkvalue: any) => {
  if (checkvalue == 'KST') return data.trim();
  else return '';
};

const getOrderNew = (data: any, checkvalue: any) => {
  if (checkvalue == 'AUF') return data.trim();
  else return '';
};
const getWBSnew = (data: any, checkvalue: any) => {
  if (checkvalue == 'PSP') return data.trim();
  else return '';
};

const getCheckboxValue = (obj: any) => {
  return obj == null || (obj[0] && obj[0].status == CHECKBOX_INDETERMINATE)
    ? null
    : obj === true || (obj[0] && obj[0].status == CHECKBOX_CHECKED)
    ? true
    : obj === false || (obj[0] && obj[0].status == CHECKBOX_UNCHECKED)
    ? false
    : null;
};

const formatISNRId = (id: number) => {
  const idISNR = id;
  const newIDISNR = idISNR.toLocaleString('en-US', {
    minimumIntegerDigits: 8,
    useGrouping: false
  });
  return newIDISNR;
};

function generateMaxInt() {
  let minm = 100000;
  let maxm = 999999;
  let data = Math.floor(Math.random() * (maxm - minm + 1)) + minm;
  return data;
}

function formatModawiData(myObject: any) {
  const KEY1 = 'ensn_ve_bsgnrd';
  const KEY2 = 'ensn_ve_bsgspd';
  const arr = [];

  for (let key in myObject) {
    if (key.includes(KEY1)) {
      const index = key.split('.')[1];
      const obj = {
        num: key,
        id: index,
        bimNr: myObject[key],
        bimSpalte: ''
      };
      arr.push(obj);
    }
  }

  for (let key in myObject) {
    if (key.includes(KEY2)) {
      const index = key.split(KEY2)[1];
      for (let j in arr) {
        if (arr[j].num.includes(index)) {
          arr[j].bimSpalte = myObject[KEY2 + index];
        } else {
          arr[j].bimSpalte = '';
        }
      }
    }
  }
  return arr;
}

function formatBegrundungData(myObject: any) {
  const KEY1 = 'ensn_bb_bgrkurz';
  const KEY2 = 'ensn_bb_bgrbeschr';
  const KEY3 = 'ensn_bb_bgrbezugnb';
  const keys: any = [KEY1, KEY2, KEY3];
  const arr: any = [];

  for (let key in myObject) {
    const index = key.split('.')[1];
    const name = key.split('.')[0];
    if (keys.includes(name)) {
      const findIndex = arr.findIndex((a: any) => a.id == index);
      const obj = {
        num: key,
        id: index,
        bimNr: name == KEY1 ? myObject[key] : '',
        besch: name == KEY2 ? myObject[key] : '',
        neben: name == KEY3 ? myObject[key] : ''
      };
      if (findIndex !== -1) {
        const updatekey =
          name == KEY1 ? 'bimNr' : name == KEY2 ? 'besch' : name == KEY3 ? 'neben' : '';
        arr[findIndex] = { ...arr[findIndex], [updatekey]: myObject[key] };
      } else {
        arr.push(obj);
      }
    }
  }

  // for (let key in myObject) {
  //   if (key.includes(KEY2)) {
  //     const index = key.split(KEY2)[1];
  //     for (let j in arr) {
  //       if (arr[j].num.includes(index)) {
  //         arr[j]['abc'] = myObject[KEY2 + index];
  //       } else {
  //         arr[j].abc = '';
  //       }
  //     }
  //   }
  // }
  return arr;
}

function anlagenSchema(value: any) {
  const data = [
    'D01',
    'D02',
    'D03',
    'D04',
    'D05',
    'D06',
    'D07',
    'D08',
    'D09',
    'D10',
    'D11',
    'D12',
    'D13',
    'D14',
    'D15',
    'R01',
    'R02',
    'R03',
    'R04',
    'R05',
    'R06',
    'R07',
    'R08',
    'R09',
    'R10',
    'R11',
    'R12',
    'R13'
  ];
  const validateData = data.includes(value);
  return validateData;
}

function formatTree(dokumenteData: any, level1: any, level2: any) {
  let arr: any = [level1, level2];

  let resultObj: any = {};

  let getAllKeys = Object.keys(dokumenteData);
  arr.forEach(function (item: any) {
    // looping through payload
    getAllKeys.forEach(function (keyName: any) {
      // using index of to check if the object key name have a matched string
      if (keyName.indexOf(item) !== -1) {
        resultObj[keyName] = dokumenteData[keyName];
      }
    });
  });

  let parent: any = {};
  let children: any = {};
  let result = Object.keys(resultObj);
  result.forEach(function (keyName: any) {
    // using index of to check if the object key name have a matched string
    if (keyName.indexOf(level1) !== -1) {
      parent[keyName] = resultObj[keyName];
    }
    if (keyName.indexOf(level2) !== -1) {
      children[keyName] = resultObj[keyName];
    }
  });

  let budesarray: any = [];
  Object.keys(parent).forEach((item: any) => {
    const parentindex = item.substring(item.indexOf('.') + 1);

    const name: any = blstateOptions.filter((element: any) => {
      return element.value.label === parent[item];
    });

    budesarray.push({
      id: parent[item],
      key: parent[item],
      name: name[0].value.value,
      localGov: []
    });

    Object.keys(children).forEach((childitem: any) => {
      const childindex: any = childitem.split('.');
      const index = parentindex - 1;

      const localGovLength = Array.isArray(
        budesarray[parentindex - 1] && budesarray[parentindex - 1].localGov
      )
        ? budesarray[parentindex - 1].localGov.length
        : 0;
      let idGenerate = localGovLength
        ? budesarray[parentindex - 1].localGov[localGovLength - 1].id
        : 0;
      idGenerate++;
      const newNo = idGenerate;

      let localobj: any = {
        id: idGenerate,
        key: `${index} ${newNo} [${children[childitem]}]`,
        name: children[childitem]
      };
      if (childindex[1] === parentindex) {
        budesarray[parentindex - 1] &&
        budesarray[parentindex - 1].localGov &&
        Array.isArray(budesarray[parentindex - 1] && budesarray[parentindex - 1].localGov)
          ? budesarray[parentindex - 1].localGov.push({ ...localobj })
          : [];
      }
    });
  });
  return budesarray;
}

//to convert decimal number to german notation
const toGermanDecimalNumber = (value: string) => {
  if (value) {
    return value.toString().replace('.', ',');
  }
};

//convert from  german decimal number notation
const fromGermanDecimalNumber = (value: string) => {
  if (value) {
    return Number(value.toString().replace(',', '.'));
  }
  return '';
};

//convert from  german decimal number notation
const fromGermanDecimalNum = (value: string) => {
  if (value) {
    return Number(value.toString().replace(',', '.'));
  }
  return 0;
};

const fromGermanDecimalNumToNormal = (value:any) => {
  if (value) {
    return (value.toString().replace(',', '.'));
  }
  return 0;
};

const formatTblData = (result: any, keys: any) => {
  let resArr: any = [];
  const tempData: any = {};
  /**filter out data based on the kyys given in the array */

  result &&
    Object.keys(result).forEach((item: any) => {
      keys.map((reg: any) => {
        let regExp: any = new RegExp(`.*${reg}.*`);
        if (item && regExp.test(item)) {
          resArr.push({ [item]: result[item] });
        }
      });
    });

  /** changing array of objects to single object */
  const resArrayFormatted: any = resArr.reduce((current: any, next: any) => {
    return { ...current, ...next };
  }, {});

  /** group values baed on .1, .2 etcc */
  Object.keys(resArrayFormatted).map((item: any) => {
    const splitVal: any = item.split('.')[1];
    if (splitVal !== undefined) {
      if (!tempData[splitVal]) {
        tempData[splitVal] = {};
      }
      tempData[splitVal][item.split('.')[0]] = resArrayFormatted[item];
    }
  });

  return Object.values(tempData);
};

const FormatBundesValuetoApi = (parent: any, child: any, bundesLand: any) => {
  const parentLabel = parent;
  const childLabel = child;
  const arrayreq: any = {};

  bundesLand.state.forEach((item: any, index: any) => {
    const parentIndex = index + 1;
    const parentKey = parentLabel + parentIndex.toString();
    arrayreq[parentKey] = item.name.slice(1, 2);
    if (item.localGov && Array.isArray(item.localGov)) {
      item.localGov.forEach((childItem: any, childIndex: any) => {
        const kriesIndex = childIndex + 1;
        const childKey = childLabel + parentIndex.toString() + '.' + kriesIndex;
        arrayreq[childKey] = childItem.name;
      });
    }
  });

  return arrayreq;
};
const totalSum = (data: any) => {
  
  let totalSum = 0;
  for (let i = 0; i < data.length; i++) {
    totalSum = totalSum + parseFloat(data[i].NUM_DISPO_MNG);
  }
  return totalSum.toFixed(3);
};
const totalSumMenge = (data: any) => {
  
  let totalSum = 0;
  for (let i = 0; i < data.length; i++) {
    totalSum =totalSum + parseFloat(fromGermanDecimalNumber(data[i].NUM_DISPO_MNG).toString());
  }
  return totalSum.toFixed(3);
};
const totalSumleft = (data: any) => {
  
  let totalSum = 0;
  for (let i = 0; i < data.length; i++) {
    totalSum = totalSum + parseFloat(data[i].NUM_ANGEN_MENGET);
  }
  return totalSum.toFixed(3);
};
const totalSumleftMenge = (data: any) => {

  let totalSum = 0;
  for (let i = 0; i < data.length; i++) {
    totalSum = totalSum + parseFloat(fromGermanDecimalNumber(data[i].NUM_ANGEN_MENGET).toString());
  }
  return totalSum.toFixed(3);
};


const signatureDataFormatter = (data: any) => {
  if (data && Object.keys(data).length !== 0) {
    const regx = /.*Unterzeichner.*/;
    let resArr: any = [];
    data &&
      Object.keys(data).map((item: any) => {
        if (item && regx.test(item)) {
          resArr.push(data[item]);
        }
      });
    return { layerName: data.LayerName, sData: resArr.reverse() };
  } else {
    return { layerName: '', sData: [] };
  }
};

const formatZweckData = (data: any) => {
  let regex = /\(|\)|\[|\]/g;
  let newZweck = data?.zweck.replace(regex, '');
  let newObj = {
    zweckKey: data.zweckKey,
    zweck: newZweck ? newZweck : ''
  };

  return newObj;
};

function formatFehlerData(myObject: any) {
  const keyArr = [
    'ensn_eb_err_klasse',
    'ensn_eb_err_an_rolle',
    'ensn_eb_err_code',
    'ensn_eb_err_text',
    'ensn_eb_err_abhilfe'
  ];
  const arr = [];

  let countObject: any = {};
  for (let i = 0; i < keyArr.length; i++) {
    countObject[keyArr[i]] = 0;
    for (let key in myObject) {
      if (key.includes(keyArr[i])) {
        countObject[keyArr[i]] += 1;
      }
    }
  }

  for (let j = countObject[keyArr[0]]; j >= 1; j--) {
    const obj = {
      Nr: j,
      Klasse: myObject['ensn_eb_err_klasse.' + j],
      anRolle: myObject['ensn_eb_err_an_rolle.' + j],
      FehlerCode: myObject['ensn_eb_err_code.' + j],
      Details: myObject['ensn_eb_err_text.' + j],
      Abhilfe: myObject['ensn_eb_err_abhilfe.' + j]
    };
    arr.push(obj);
  }

  return arr;
}

const formatRegisterValue = (value: any) => {
  if (value === 'Y') {
    return 'J';
  } else if (value === 'N') {
    return value;
  }
};

const replaceDotWithComma = (input : any) => {
    const text = String(input);
    if(text.includes('.')) {
      const data = text.replace('.', ',');
      return data; 
    } else {
      const data = `${text},000`;
      return data;
    }
     
}

export {
  formatTree,
  replaceDotWithComma,
  getFromStorage,
  setToStorage,
  removeFromStorage,
  formatDateTime,
  formatAMPM,
  formatTimeandDate,
  IstValideKombination,
  IstValideKombinationII,
  CheckValideBGSNr,
  CheckValideUNSNr,
  CheckValideENNr,
  CheckValideENNrOhnePruefziffer,
  CheckValideBGSNrOhnePruefziffer,
  CheckValideUNSNrOhnePruefziffer,
  CheckValideTeilnehmerNr,
  CheckValideTeilnehmerNrOhnePruefziffer,
  CheckValideNNNr,
  TranslateBundesland,
  TranslateRolle,
  BerechnePruefziffer,
  SplitLastCharacter,
  formatDateToApi,
  SplitLastCharacterAsArray,
  officialNumber,
  controlNumber,
  formatNewDate,
  downloadAttachment,
  quantityFormatter,
  getActivityUnit,
  getCostsenterNew,
  getOrderNew,
  getWBSnew,
  getCheckboxValue,
  formatISNRId,
  generateMaxInt,
  formatModawiData,
  anlagenSchema,
  toGermanDecimalNumber,
  fromGermanDecimalNumber,
  fromGermanDecimalNum,
  formatBegrundungData,
  formatTblData,
  FormatBundesValuetoApi,
  totalSum,
  signatureDataFormatter,
  formatZweckData,
  totalSumleft,
  formatFehlerData,
  FreistellungPruefziffer,
  formatRegisterValue,
  setToLocalStorage,
  getFromLocalStorage,
  removeFromLocalStorage,
  fromGermanDecimalNumToNormal,
  totalSumMenge,
  totalSumleftMenge
};
