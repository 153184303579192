import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { loaderSubject } from 'helpers/loader.helper';
import './Loader.scss';

export function Loader() {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    loaderSubject?.subscribe((value: boolean) => {
      setIsLoading(value);
    });
  }, []);

  return isLoading ? (
    <Grid container className='loader-container'>
      <Grid item xs={12} className='loader-parent'>
        <Box className='progress-container'>
          <CircularProgress color='inherit' size={60} />
        </Box>
      </Grid>
    </Grid>
  ) : null;
}
