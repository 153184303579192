import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { getFromStorage } from 'core/utils/utils.helper';
import { getRouteFromKey, getKeyFromRoute, getPath } from 'helpers/permissions.helper';
import { RenderTree } from 'models/tree';
import { STORAGE_USER_PERMISSIONS } from 'constants/storage';
import { REGEX_BASE64 } from 'constants/regex';
import './Tree.scss';

export const Tree = (props: any) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const nodesArr: any[] = [];
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    setExpanded([]);
    setSelected([]);
    const key = getKeyFromRoute(location.pathname);
    if (
      getFromStorage(STORAGE_USER_PERMISSIONS) &&
      REGEX_BASE64.test(getFromStorage(STORAGE_USER_PERMISSIONS) || '')
    ) {
      // get the user permission details from browser storage
      // and identify the hierarchy of modules based on the current route
      let permissionArray = JSON.parse(atob(getFromStorage(STORAGE_USER_PERMISSIONS) || ''));
      const currentHierarchy = getPath(permissionArray, key);
      setExpanded(currentHierarchy); // expand all the items so as to display the current module
      if (currentHierarchy && Array.isArray(currentHierarchy) && currentHierarchy.length) {
        // select the currently selected module, which is the last item of hierarchy array
        setSelected([currentHierarchy[currentHierarchy.length - 1]]);
      }
    }
  }, [path]);

  /**
   * Creating array of IDs of all nodes
   */
  const parentNodes = (nodes: RenderTree) => {
    if (nodes) {
      nodesArr.push(nodes.key);
      if (Array.isArray(nodes.children)) {
        nodes.children.forEach(node => parentNodes(node));
      }
    }
  };
  parentNodes(props.data);

  /**
   * Navigate to pages upon sidebar menu item click
   * @param node
   */
  const handleSidebarClick = (node: RenderTree) => {
    const route = getRouteFromKey(node.key);
    route && route.path && navigate(route.path);
  };

  /**
   * Expands any item from sidebar
   * @param key
   */
  const expandItem = (key: string) => {
    let newExpanded = [...[key]];
    expanded.forEach((expandedItem: string) => {
      if (newExpanded.indexOf(expandedItem) === -1) {
        newExpanded.push(expandedItem);
      }
    });
    setExpanded(newExpanded);
  };

  /**
   * Collapses an item
   * @param key
   */
  const collapseItem = (key: string) => {
    const index = expanded.indexOf(key);
    const newExpanded =
      index > -1
        ? expanded.filter((expandedItem: string) => {
            return expandedItem !== key;
          })
        : expanded;
    setExpanded(newExpanded);
  };

  /**
   * Traversing through all nodes
   */
  const renderTree = (nodes: RenderTree) => (
    <TreeItem
      key={nodes.key}
      nodeId={nodes.key}
      collapseIcon={<ExpandMoreIcon onClick={() => collapseItem(nodes.key)} color='inherit' />}
      expandIcon={<ChevronRightIcon onClick={() => expandItem(nodes.key)} color='inherit' />}
      label={
        <span
          onClick={() => {
            handleSidebarClick(nodes);
          }}
        >
          {nodes.label}
        </span>
      }
    >
      {Array.isArray(nodes.children) ? nodes.children.map(node => renderTree(node)) : null}
    </TreeItem>
  );

  return (
    expanded && (
      <Grid container className='tree-container'>
        <Grid item xs={12}>
          {props.data &&
            props.data.children &&
            Array.isArray(props.data.children) &&
            props.data.children.map((item: RenderTree) => {
              return (
                <TreeView key={item.key} expanded={expanded} selected={selected} multiSelect>
                  {renderTree(item)}
                </TreeView>
              );
            })}
        </Grid>
      </Grid>
    )
  );
};
