import { routes } from 'constants/routes';
import { matchPath } from 'react-router-dom';
/**
 * Prepare a hierarchy for the permissions
 * Eg: Admin -> Role -> New Role etc
 * @param data
 * @returns
 */
const hierarchy = (data: { children?: any; KEY_APPMODULE?: any; KEY_PAPPMODULE?: any }[]) => {
  const tree: any[] = [];
  const childOf: any = {};
  data.forEach(
    (item: { children?: any; KEY_APPMODULE?: any; KEY_PAPPMODULE?: any; TXT_APPMODULE?: any }) => {
      const { KEY_APPMODULE, KEY_PAPPMODULE, TXT_APPMODULE } = item;
      childOf[KEY_APPMODULE] = childOf[KEY_APPMODULE] || [];
      const menuItemObj = {
        key: KEY_APPMODULE,
        label: TXT_APPMODULE,
        children: childOf[KEY_APPMODULE]
      };
      KEY_PAPPMODULE
        ? (childOf[KEY_PAPPMODULE] = childOf[KEY_PAPPMODULE] || []).push(menuItemObj)
        : tree.push(menuItemObj);
    }
  );
  return tree;
};

/**
 * Identify the hierarchial order of one particular module
 * Returns the module keys as an array in the order of their occurences
 * @param obj
 * @param key
 * @returns string[]
 */
const getPath = (obj: any, key: string) => {
  // We need to store path
  // Start stack with root nodes
  let stack = obj.map((item: any) => ({ path: [item.key], currObj: item }));
  while (stack.length) {
    const { path, currObj } = stack.pop();
    if (currObj.key === key) {
      return path;
    } else if (currObj.children?.length) {
      stack = stack.concat(
        currObj.children.map((item: any) => ({ path: path.concat(item.key), currObj: item }))
      );
    }
  }
  return null; // if id does not exists
};

/**
 * Prepare permission hierarchy using the 'hierarchy' method defined above
 * Formats the info from API
 * @param data
 * @returns
 */
const preparePermissionHierarchy = (data: any) => {
  let permissions: any = [];
  if (data && Array.isArray(data)) {
    data.forEach((module: any) => {
      // API returns parent module of major modules as 'Home', which is a wrong info
      // Set those to null
      if (module.KEY_PAPPMODULE && module.KEY_PAPPMODULE === 'A10000') {
        module.KEY_PAPPMODULE = null;
      }
      return module;
    });
    permissions = hierarchy(data);
  }
  return permissions;
};

/**
 * Returns the route based on the location string provided
 * @param pathName
 * @returns
 */
const getRouteFromLocation = (pathName: string) => {
  let currentRoute: any = null;
  (Object.keys(routes) as Array<keyof typeof routes>).forEach(value => {
    // match current path with common routes const
    const match = matchPath(routes[value], pathName);
    if (match) {
      currentRoute = routes[value];
    }
  });
  return currentRoute;
};

/**
 * Returns the route based on module key
 * @param key
 * @returns
 */
const getRouteFromKey = (moduleKey: string) => {
  let route: any = null;
  (Object.keys(routes) as Array<keyof typeof routes>).forEach(value => {
    if (routes[value].key === moduleKey) {
      route = routes[value];
    }
  });
  return route;
};

const getKeyFromRoute = (modulePath: string) => {
  let key: any = null;
  (Object.keys(routes) as Array<keyof typeof routes>).forEach(value => {
    // console.log("vaaaaal", routes[value])
    if (routes[value].path === modulePath) {
      key = routes[value].key;
    }
  });
  return key;
};

export {
  preparePermissionHierarchy,
  getPath,
  getRouteFromLocation,
  getRouteFromKey,
  getKeyFromRoute
};
