export const apiEndpoints = {
  authenticate: {
    url: 'LogIn/ValidateUser', // '70dc84eb-fdcb-4969-9560-540d9c11855e',
    isMock: false
  },
  authenticateAndGetToken: {
    url: 'LogIn/GetToken', // '70dc84eb-fdcb-4969-9560-540d9c11855e',
    isMock: false
  },
  userInfo: {
    url: 'Admin/FindByLoginname_Benutzer',
    isMock: false
  },
  countries: {
    url: '25c0d78d-7f84-4995-aa19-b59357daf50b',
    isMock: false
  },
  states: {
    url: '1f651783-1a2b-457f-8a6c-f278586acd39',
    isMock: false
  },
  inetnalCostAllocation: {
    url: 'Admin/Rechnungslauf_Uebersicht',
    isMock: false
  },
  detailListingILV: {
    url: 'Admin/LVRDaten_Selektieren',
    isMock: false
  },
  getReportCurrentUser: {
    url: 'LogIn/GetAssignedReportsForCurrentUser',
    isMock: false
  },
  roleDetails: {
    url: 'Admin/GetSingleAnwendungsrolle',
    isMock: false
  },
  sameRoleDetails: {
    url: 'Admin/GetAlleBenutzerInRolle',
    isMock: false
  },
  roleListDetails: {
    url: 'Admin/FetchALL_AppRolesByApplication',
    isMock: false
  },
  roleModuleInfo: {
    url: 'Admin/GetAppRoleModules',
    isMock: false
  },
  addRoleInfo: {
    url: 'Admin/CreateAnwendungsrolle',
    isMock: false
  },
  editRoleInfo: {
    url: 'Admin/UpdateAnwendungsrolleContent',
    isMock: false
  },
  deleteRoleInfo: {
    url: 'Admin/DeleteAnwendungsrolle',
    isMock: false
  },
  locationDetails: {
    url: 'Stammdaten/GetALL_V_STANDORT',
    isMock: false
  },
  addLocationDetails: {
    url: 'Stammdaten/CreateStandort',
    isMock: false
  },
  updateLocationDetails: {
    url: 'Stammdaten/Update_Standort',
    isMock: false
  },
  getLocationByLocation: {
    url: 'Stammdaten/GetSingle_VStandortByID',
    isMock: false
  },
  getStandort: {
    url: 'Stammdaten/GetSingle_StandortByID',
    isMock: false
  },
  getCorporationDetails: {
    url: 'Stammdaten/GetALL_V_KOERPERSCHAFT',
    isMock: false
  },
  getParticipantsByLocation: {
    url: 'Stammdaten/GetVTeilnehmerByID_STANDORT',
    isMock: false
  },
  getLagerByLocation: {
    url: 'Stammdaten/GetVLagerByID_STANDORT',
    isMock: false
  },
  deleteLocation: {
    url: 'Stammdaten/Delete_Standort',
    isMock: false
  },
  clear_request: {
    url: 'request.json',
    isMock: true
  },
  clear_request_response: {
    url: 'clear_request_response.json',
    isMock: true
  },
  deleteRequest: {
    url: 'Admin/DeleteAbrufData',
    isMock: false
  },
  userdetailInfo: {
    url: 'Admin/FindByLoginname_Benutzer',
    isMock: false
  },
  userlist: {
    url: 'Admin/FetchALL_Benutzer',
    isMock: false
  },
  deleteuser: {
    url: 'Admin/Delete_Benutzer',
    isMock: false
  },
  adduser: {
    url: 'Admin/CreateBenutzer',
    isMock: false
  },
  updateuser: {
    url: 'Admin/Update_Benutzer',
    isMock: false
  },
  createNewinvoice: {
    url: 'Admin/Rechnungslauf_Erstellen',
    isMock: false
  },
  updateInvoiceApi: {
    url: 'Admin/Rechnungslauf_Bestaetigen',
    isMock: false
  },
  deleteRunPayroll: {
    url: 'Admin/Rechnungslauf_Loeschen',
    isMock: false
  },
  docCheckInReq: {
    url: 'Admin/UpdateDocumentStatus',
    isMock: false
  },
  organisationLandingPage: {
    url: 'Stammdaten/GetALL_V_OBJECT',
    isMock: false
  },
  loggedInUserPermissions: {
    url: 'Admin/FetchALL_UserSideBarAppModulesByApplication',
    isMock: false
  },
  updateCostCenter: {
    url: 'Admin/Upd_Kst_Rechnungslauf',
    isMock: false
  },
  listTransporter: {
    url: 'Stammdaten/GetALL_V_TEILNEHMER',
    isMock: false
  },

  listProvider: {
    url: 'Stammdaten/GetALL_V_TEILNEHMER',
    isMock: false
  },
  corporationList: {
    url: 'corporationList.json',
    isMock: true
  },
  corporationLists: {
    url: 'Stammdaten/GetALL_V_KOERPERSCHAFT',
    isMock: false
  },
  notificationList: {
    url: 'Admin/GetmessageList?ID_MESSAGE=0',
    isMock: false
  },
  notificationListToday: {
    url: 'Admin/GetmessageFromDb',
    isMock: false
  },
  deleteNotification: {
    url: 'Admin/DeleteAdministratorData',
    isMock: false
  },
  createNotification: {
    url: 'Admin/SaveAdministratorData',
    isMock: false
  },
  updateNotification: {
    url: 'Admin/UpdateAdministratorData',
    isMock: false
  },
  notificationDetails: {
    url: 'Admin/GetmessageList',
    isMock: false
  },
  producerList: {
    url: 'producerList.json',
    isMock: true
  },
  producerOriginAPI: {
    url: 'Stammdaten/GetSingle_AnfallstelleByID',
    isMock: false
  },
  costCenterList: {
    url: 'costCenter.json',
    isMock: true
  },
  storageList: {
    url: 'Stammdaten/GetALL_V_LAGER',
    isMock: false
  },

  editStorageList: {
    url: 'Stammdaten/GetSingle_VLagerByID',
    isMock: false
  },
  addStorageModal: {
    url: 'Stammdaten/GetALL_V_STANDORT',
    isMock: false
  },
  deleteLager: {
    url: 'Stammdaten/DeleteLager',
    isMock: false
  },
  createLager: {
    url: 'Stammdaten/CreateLager',
    isMock: false
  },
  updateLager: {
    url: 'Stammdaten/Update_Lager',
    isMock: false
  },
  getTeilnehmerListbyLager: {
    url: 'Stammdaten/GetTeilnehmerLagerByID_LAGER',
    isMock: false
  },
  getallLagerArea: {
    url: 'Stammdaten/GetLagerbereichByID_LAGER',
    isMock: false
  },
  getLagerReport: {
    url: 'Report/GetReport_FeuerwehrReport',
    isMock: false
  },
  getUnblockedLager: {
    url: 'Stammdaten/GetALL_V_LAGERForUser',
    isMock: false
  },
  splittenLager: {
    url: 'Lager/TransferLagereinheitenTeil',
    isMock: false
  },
  transferStorageUnit: {
    url: 'Lager/TransferLagereinheitenKomplett',
    isMock: false
  },

  updateLagerArea: {
    url: 'Stammdaten/Update_Lagerbereich',
    isMock: false
  },
  addNewLagerArea: {
    url: 'Stammdaten/Create_LagerBereich',
    isMock: false
  },
  deleteLagerArea: {
    url: 'Stammdaten/Delete_Lagerbereich',
    isMock: false
  },
  changepassword: {
    url: 'Admin/Change_Password',
    isMock: false
  },
  getEanvDokumente: {
    url: 'eANVDocumentService/Managed_GetFullLayerDocumentInfo',
    isMock: false
  },
  updateTeilnehmer: {
    url: 'Stammdaten/Update_Teilnehmer',
    isMock: false
  },
  listDisposer: {
    // url: 'disposerList.json',
    url: 'Stammdaten/GetALL_V_TEILNEHMER',
    isMock: false
  },
  getSingleDisposer: {
    url: 'Stammdaten/GetSingle_VTeilnehmerByID',
    isMock: false
  },
  anlagenProfileDetails: {
    url: 'Stammdaten/GetAnlagenProfileByID_TEILNEHMER',
    isMock: false
  },
  entsorgerProfile: {
    url: 'Stammdaten/GetEntsorgerProfil',
    isMock: false
  },
  lagerzuordnung: {
    // url: 'disposerList.json',
    url: 'Stammdaten/GetTeilnehmerLagerByID_TEILNEHMER',
    isMock: false
  },
  lagerHinzufugen: {
    url: 'Stammdaten/GetALL_V_LAGER',
    isMock: false
  },
  assignLagerToTeilnehmer: {
    url: 'Stammdaten/AssignLagerToTeilnehmer',
    isMock: false
  },
  removeLagerAssignment: {
    url: 'Stammdaten/RemoveLagerToTeilnehmerAssignment',
    isMock: false
  },
  createAnlagenProfile: {
    url: 'Stammdaten/Create_AnlagenProfil',
    isMock: false
  },
  deleteAnlagenProfile: {
    url: 'Stammdaten/Delete_AnlagenProfil',
    isMock: false
  },
  updateAnlagenProfile: {
    url: 'Stammdaten/Update_AnlagenProfil',
    isMock: false
  },
  updateEntsorgerProfil: {
    url: 'Stammdaten/Update_EntsorgerProfil',
    isMock: false
  },
  locationsList: {
    url: 'Stammdaten/GetALL_V_STANDORT',
    isMock: false
  },
  disposerApi: {
    url: 'test-url',
    isMock: false
  },
  warehouseList: {
    url: 'warehouseList.json',
    isMock: true
  },
  userrolesinfo: {
    url: 'Admin/GetALLUserAssignedRoles',
    isMock: false
  },
  producerOriginList: {
    url: 'Stammdaten/GetALL_V_ANFALLSTELLE',
    isMock: false
  },
  getAnfallstelleBimList: {
    url: 'Stammdaten/GetALL_BImSchVByAnfallstelle',
    isMock: false
  },
  anfallstellenDetails: {
    url: 'Stammdaten/GetALL_Typ1Entsorgungsleistungen',
    isMock: false
  },
  furAnfallstellenSubmit: {
    url: 'Stammdaten/UpdateTyp1Entsorgungsleistungen',
    isMock: false
  },

  furAnfallstellenAbfallerzeugung: {
    url: 'Stammdaten/GetSingle_Typ1Entsorgungsleistungen',
    isMock: false
  },

  furAnfallstellenBeforderer: {
    url: 'Stammdaten/GetALL_MoeglichebefoerdererZumEWeg',
    isMock: false
  },

  furAnfallstellenBefordererEdit: {
    url: 'Stammdaten/Update_MoeglicheBefoerderer',
    isMock: false
  },
  trasporterModalListStandort: {
    url: 'Stammdaten/GetALL_V_STANDORT',
    isMock: false
  },

  furAnfallstellenBefordererDelete: {
    url: 'Stammdaten/Delete_MoeglicheBefoerderer',
    isMock: false
  },
  furAnfallstellenDropdown: {
    url: 'Stammdaten/GetTeilnehmerLagerByID_TEILNEHMER',
    isMock: false
  },
  furAnfallstellenBefordererCreate: {
    url: 'Stammdaten/Create_MoeglicheBefoerderer',
    isMock: false
  },

  EntsorgungswegDetails: {
    url: 'Stammdaten/GetALL_Typ1EWegeByID_ERZEUGUNG',
    isMock: false
  },
  providerModalList: {
    url: 'trasporter.json',
    isMock: true
  },
  trasporterModalList: {
    url: 'trasporter.json',
    isMock: true
  },
  trasporterModalListzum: {
    url: 'Abfall/GetALL_V_ABFALLSTAMM',
    isMock: false
  },
  trasporterModalListzumErzeuger: {
    url: 'Stammdaten/GetALL_V_TEILNEHMER',
    isMock: false
  },
  avvKatalog: {
    url: 'Stammdaten/GetALL_TBL_AVV_ABFART',
    isMock: false
  },
  leistungsarten: {
    url: 'Stammdaten/GetALL_TBL_LEISTUNGSART',
    isMock: false
  },
  wasteOriginList: {
    url: 'Abfall/GetALL_V_ABFALLSTAMM',
    isMock: false
  },
  createBestandteile: {
    url: 'Abfall/Create_BEST_TEIL',
    isMock: false
  },
  saveMoreWeitereAvv: {
    url: 'Nachweis/Create_MoreASN',
    isMock: false
  },
  deleteMoreAvv: {
    url: 'Nachweis/Delete_MoreASN',
    isMock: false
  },
  updateBestandteile: {
    url: 'Abfall/Update_BEST_TEIL',
    isMock: false
  },
  createNewHazardousData: {
    url: 'Abfall/Create_GGVS_NEB',
    isMock: false
  },
  updateSingleHazardousData: {
    url: 'Abfall/Update_GGVS_NEB',
    isMock: false
  },
  updateHazardousMainFormData: {
    url: 'Abfall/Update_GGVS',
    isMock: false
  },
  saveHazardousMainFormData: {
    url: 'Abfall/Create_GGVS',
    isMock: false
  },
  deleteHazardousWasteFromList: {
    url: 'Abfall/Delete_GGVS_NEB',
    isMock: false
  },
  deleteHazardousWasteMainForm: {
    url: 'Abfall/Delete_GGVS',
    isMock: false
  },
  componentList: {
    url: 'Abfall/GetBEST_TEILzumAbfallstamm',
    isMock: false
  },
  deleteComponent: {
    url: 'Abfall/Delete_BEST_TEIL',
    isMock: false
  },
  hazardousWasteList: {
    url: 'Abfall/GetALL_GGVS_NEB_zumAbfallstamm',
    isMock: false
  },
  hazardousWasteData: {
    url: 'Abfall/GetGGVSzumAbfallstamm',
    isMock: true
  },
  listPackageForms: {
    url: 'Stammdaten/GetALL_TBL_GEBINDEFORM',
    isMock: false
  },
  listPackageFormsAbfallstamm: {
    url: 'Stammdaten/GetGebindeAssignedByAbfstammID',
    isMock: false
  },
  addLeistungsarten: {
    url: 'Stammdaten/Create_LEISTUNGSART',
    isMock: false
  },
  pointOfOriginList: {
    url: 'Stammdaten/GetALL_Typ1EWege',
    isMock: false
  },
  pointOfOriginDetails: {
    url: 'Stammdaten/GetSingle_Typ1Eweg',
    isMock: false
  },
  pointOfOriginMogDetails: {
    url: 'Stammdaten/GetALL_MoeglichebefoerdererZumEWeg',
    isMock: false
  },
  disposalRoutesAuswahlBefordererList: {
    url: 'Stammdaten/GetALL_V_TEILNEHMER',
    isMock: false
  },
  addDisposalRoutesAuswahlBefordererList: {
    url: 'Stammdaten/Create_MoeglicheBefoerderer',
    isMock: false
  },
  editDisposalRoutesAuswahlBefordererList: {
    url: 'Stammdaten/Update_MoeglicheBefoerderer',
    isMock: false
  },
  ErzeugerList: {
    url: 'Stammdaten/GetALL_Typ2Entsorgungsleistungen',
    isMock: false
  },
  ErzeugerEditmodalList: {
    url: 'Stammdaten/GetALL_V_TEILNEHMER',
    isMock: false
  },
  EditErzeugerEntsorgungswegTableData: {
    url: 'Stammdaten/GetALL_Typ2EWegeByID_ERZEUGUNG',
    isMock: false
  },
  Editerzeugermoglichetabledata: {
    url: 'Stammdaten/GetALL_MoeglichebefoerdererZumEWeg',
    isMock: false
  },
  EditerzeugermoglicheDelete: {
    url: 'Stammdaten/Delete_MoeglicheBefoerderer',
    isMock: false
  },
  ErzeugerEdit: {
    url: '',
    isMock: true
  },
  wasteSourceInfo: {
    url: 'wasteSource.json',
    isMock: true
  },
  getAvvList: {
    url: 'Stammdaten/GetALL_TBL_AVV_ABFART',
    isMock: false
  },
  getLagerClassList: {
    url: 'Stammdaten/GetALL_TBL_LGK',
    isMock: false
  },
  getPerformanceTpeList: {
    url: 'Stammdaten/GetALL_TBL_LEISTUNGSART',
    isMock: false
  },
  addNewWasteOrigin: {
    url: 'Abfall/Create_Abfallstamm',
    isMock: false
  },
  getSingleWasteOriginInfo: {
    url: 'Abfall/GetSingle_V_ABFALLSTAMMByID',
    isMock: false
  },
  updateWasteOriginInfo: {
    url: 'Abfall/Update_Abfallstamm',
    isMock: false
  },
  deleteWasteOriginInfo: {
    url: 'Abfall/Delete_Abfallstamm',
    isMock: false
  },

  addNewPackage: {
    url: 'Stammdaten/Create_GEBINDEFORM',
    isMock: false
  },
  editPackage: {
    url: 'Stammdaten/GetSingle_GEBINDEFORM',
    isMock: false
  },
  updatePackage: {
    url: 'Stammdaten/Update_GEBINDEFORM',
    isMock: false
  },
  listAbfallstamme: {
    url: 'Stammdaten/GetGebindeAssignedByGebindeformID',
    isMock: false
  },
  deletePackage: {
    url: 'Stammdaten/Delete_GEBINDEFORM',
    isMock: false
  },
  listOneTransporter: {
    url: 'Stammdaten/GetVTeilnehmerByID_STANDORT',
    isMock: false
  },

  temporaryStorageList: {
    url: 'temporaryStorage.json',
    isMock: true
  },
  registerAnforderungen: {
    url: 'registerAnforderung.json',
    isMock: true
  },
  collectorToDisposerList: {
    url: 'Stammdaten/GetALL_Typ3EWege',
    isMock: false
  },
  collectorToDisposerDetails: {
    url: 'Stammdaten/GetSingle_Typ3Eweg',
    isMock: false
  },
  collectorToDisposerMogDetails: {
    url: 'Stammdaten/GetALL_MoeglichebefoerdererZumEWeg',
    isMock: false
  },
  collectorToDisposerAuswahlBefordererList: {
    url: 'collectorToDisposerAuswahlBefordererList.json',
    isMock: true
  },
  producerToDisposerList: {
    url: 'Stammdaten/GetALL_Typ2EWege',
    isMock: false
  },
  producerToDisposerDetails: {
    url: 'Stammdaten/GetSingle_Typ2Eweg',
    isMock: false
  },
  producerToDisposerMogDetails: {
    url: 'Stammdaten/GetALL_MoeglichebefoerdererZumEWeg',
    isMock: false
  },
  producerToDisposerAuswahlBefordererList: {
    url: 'producerToDisposerAuswahlBefordererList.json',
    isMock: true
  },
  furSammlerList: {
    url: 'Stammdaten/GetALL_Typ3Entsorgungsleistungen',
    isMock: false
  },
  editLeistungsarten: {
    url: 'Stammdaten/GetSingle_LEISTUNGSART',
    isMock: false
  },
  updateLeistungsarten: {
    url: 'Stammdaten/Update_LEISTUNGSART',
    isMock: false
  },
  ensorgerList: {
    url: 'ensorgerList.json',
    isMock: true
  },
  wasteStrainListUrl: {
    url: 'wasteStrainSelection.json',
    isMock: true
  },

  mockCostCenterWasteGeneration: {
    // url:'Stammdaten/GetALL_V_ANFALLSTELLEForUser',
    url: 'costCenterWasteGeneration.json',
    isMock: true
  },

  ZurAbfallstammMock: {
    url: 'ZurAbfallstamm.json',
    isMock: true
  },
  zumErzeuger: {
    url: 'zumErzeuger.json',
    isMock: true
  },
  ZurAbfallstammLeft: {
    url: 'Stammdaten/GetALL_V_TEILNEHMER',
    isMock: false
  },
  ZurAbfallstammRight: {
    url: 'Stammdaten/GetALL_Typ2EntsorgungsleistungenByID_ABFSTAMM',
    isMock: false
  },
  ZurerzeugerLeft: {
    url: 'Abfall/GetALL_V_ABFALLSTAMM',
    isMock: false
  },
  ZurerzeugerRight: {
    url: 'Stammdaten/GetALL_Typ2EntsorgungsleistungenByID_ABRUFORG',
    isMock: false
  },
  ZurerzeugerAttached: {
    url: 'Stammdaten/ErstelleTyp2Entsorgungsleistungen',
    isMock: false
  },
  ZurAbfallstammDetach: {
    url: 'Stammdaten/LoescheTyp2Entsorgungsleistungen',
    isMock: false
  },
  UserassignedRole: {
    url: 'Admin/GetAlleRollenFuerBenutzer',
    isMock: false
  },
  AddassignedRole: {
    url: 'Admin/AutorisiereBenutzerStandard',
    isMock: false
  },
  DeleteassignedRole: {
    url: 'Admin/EntzieheBenutzerRolle',
    isMock: false
  },
  fetchUserAssignedRoleOrg: {
    url: 'Admin/FetchALL_UserAssignedRoleOrgs',
    isMock: false
  },
  fetchOrgUnits: {
    url: 'Stammdaten/GetALL_V_OBJECT',
    isMock: false
  },
  assignOrgUnit: {
    url: 'Admin/AutorisiereBenutzerRolleOrg',
    isMock: false
  },
  removeOrgUnit: {
    url: 'Admin/EntzieheBenutzerRolleOrg',
    isMock: false
  },
  autodispositionen: {
    url: 'Stammdaten/GetALL_V_AUTODISPLAGER',
    isMock: false
  },

  teilnehmerList: {
    url: 'Stammdaten/GetALL_V_TEILNEHMER',
    isMock: false
  },
  teilnehmerDetails: {
    url: 'Stammdaten/GetSingle_VTeilnehmerByID',
    isMock: false
  },
  certificateList: {
    url: 'Teilnehmer/ListCertificates',
    isMock: false
  },
  teilnehmerAnlaggenProfile: {
    url: 'Stammdaten/GetAnlagenProfileByID_TEILNEHMER',
    isMock: false
  },
  costCenterList1: {
    url: 'Stammdaten/GetALL_V_KOSTENSTELLE',
    isMock: false
  },
  getSingleCostCenter: {
    url: 'Stammdaten/GetSingle_KOSTENSTELLE',
    isMock: false
  },
  costCenterType: {
    url: 'Stammdaten/GetALL_TBL_KOSTENSTELLETYP',
    isMock: false
  },
  costCenterCreate: {
    url: 'Stammdaten/Create_KOSTENSTELLE',
    isMock: false
  },

  costCenterAssignedOrg: {
    url: 'Stammdaten/GetV_ORGKSTLAssignedToKostenstelle',
    isMock: false
  },
  costCenterUpdate: {
    url: 'Stammdaten/Update_KOSTENSTELLE',
    isMock: false
  },
  costCenterDelete: {
    url: 'Stammdaten/Delete_KOSTENSTELLE',
    isMock: false
  },
  // sammlersList: {
  //   url: 'sammlersList.json',
  //   isMock: true
  // },
  sammlersList: {
    url: 'Stammdaten/GetALL_V_TEILNEHMER',
    isMock: false
  },
  assignedDataList: {
    url: 'Stammdaten/GetALL_Typ3EntsorgungsleistungenByID_ABFSTAMM',
    isMock: false
  },
  sammlerAbfallstammList: {
    url: 'Abfall/GetALL_V_ABFALLSTAMM',
    isMock: false
  },
  annahmeDetails: {
    url: 'lagerAnnahme.json',
    isMock: true
  },
  getAnnahmeList: {
    url: 'Lager/GetALL_LagerAnlieferungenForUser',
    isMock: false
  },
  getAnhange: {
    url: 'Stammdaten/GetCountALL_TBL_ATTACHMENT_ABRUFByID',
    isMock: false
  },
  getAngenommeneList: {
    url: 'Lager/GetALL_LagerAnlieferungenForUser',
    isMock: false
  },
  acceptCall: {
    url: 'Lager/AnnahmeLagerDurchfuehrenT1',
    isMock: false
  },
  getAnnahmeBestatigung: {
    url: 'Stammdaten/GetSingle_VAbruf',
    isMock: false
  },
  getAngenommene: {
    url: 'Lager/GetSingleAnlieferung',
    isMock: false
  },
  getAngenommeneInfoData: {
    url: 'AnalyticService/Single_MengenVerbleibABRUF',
    isMock: false
  },
  lagerAngenommeneDocumentList: {
    url: 'Stammdaten/GetALL_TBL_ATTACHMENT_ABRUFByID',
    isMock: false
  },
  submitAngenommene: {
    url: 'Lager/AnlieferungBemerkungAktualisieren',
    isMock: false
  },
  getKorrektur: {
    url: 'Lager/GetLagereinheiten',
    isMock: false
  },
  getLagerUnits: {
    url: 'Lager/GetALL_LagerUnitsByID_ABRUF',
    isMock: false
  },
  submitKorrektur: {
    url: 'Lager/AnnahmeLagerKorrektur',
    isMock: false
  },
  editKorrektur: {
    url: 'Lager/AnnahmeLagerKorrekturUpdate',
    isMock: false
  },

  bgsnDetails: {
    url: 'BGSNrZuteilung.json',
    isMock: true
  },

  sammlerWasteCollectorsList: {
    url: 'Stammdaten/GetALL_V_TEILNEHMER',
    isMock: false
  },
  zumSammlersList: {
    url: 'zumSammler.json',
    isMock: true
  },
  individualReportsList: {
    url: 'AnalyticService/GetALL_REPORT',
    isMock: false
  },
  verfugbareSplaten: {
    url: 'AnalyticService/GetALL_REPCOL_BYREPORT',
    isMock: false
  },
  datenquelle: {
    url: 'AnalyticService/GetALL_REPV',
    isMock: false
  },
  editIndividualReport: {
    url: 'AnalyticService/GetSingle_REPORT',
    isMock: false
  },
  datenvorschauList: {
    url: 'AnalyticService/GetReportPreview',
    isMock: false
  },
  veroffentlichungList: {
    url: 'AnalyticService/GetReportAssignmentsByReportID',
    isMock: false
  },
  auswahlBenutzerList: {
    url: 'Admin/FetchALL_Benutzer',
    isMock: false
  },
  restriktionenAusdruckSpalte: {
    url: 'AnalyticService/GetALL_REPCOL_BYREPORT',
    isMock: false
  },
  restriktionenAusdruckSpalteDetails: {
    url: 'AnalyticService/GETOPERATORLIST_REPORT',
    isMock: false
  },
  restriktonenEditList: {
    url: 'AnalyticService/Get_WhereRestriction',
    isMock: false
  },
  dispoList: {
    url: 'Stammdaten/GetALL_VAbrufe',
    isMock: false
  },
  listEntsorgungsakten: {
    url: 'Nachweis/GetALL_V_ENSN',
    isMock: false
  },
  listTyp1Dispositionsweg: {
    url: 'Stammdaten/GetALL_Typ1EWegeByID_ERZEUGUNG',
    isMock: false
  },
  listBeforderer: {
    url: 'Stammdaten/GetALL_MoeglichebefoerdererZumEWeg',
    isMock: false
  },
  listZiellager: {
    url: 'Stammdaten/GetTeilnehmerLagerByID_TEILNEHMER',
    isMock: false
  },
  dispoDocumentList: {
    url: 'Stammdaten/GetALL_TBL_ATTACHMENT_ENByFk',
    isMock: false
  },
  NachAnlieferungList: {
    url: 'Stammdaten/GetLagerbereichByID_LAGER',
    isMock: false
  },
  NachAnlieferungWasteDisplosalList: {
    url: 'Lager/GetALL_V_LAGERBESTANDByBereichIDs',
    isMock: false
  },
  SammelentsorgungswegList: {
    url: 'Stammdaten/GetALL_Typ3EWege',
    isMock: false
  },
  EntsorgungswegList: {
    url: 'Stammdaten/GetALL_Typ2EWege',
    isMock: false
  },
  weitereList: {
    url: 'Stammdaten/GetALL_TBL_AVV_ABFART',
    isMock: false
  },
  savedWeitereList: {
    url: 'Nachweis/GetMoreViewASNzurEntsorgungsakte',
    isMock: false
  },
  disposalDocumentList: {
    url: 'Stammdaten/GetALL_TBL_ATTACHMENT_ENByID', // Need to be changed
    isMock: false
  },
  entsorgungsaktenDocumentList: {
    url: 'Stammdaten/GetALL_TBL_ATTACHMENT_ENByID',
    isMock: false
  },
  internalEntsorgung: {
    url: 'Stammdaten/GetALL_V_TEILNEHMERForUser',
    isMock: false
  },
  logbuch: {
    url: 'AnalyticService/GetALL_V_INTERN_ENTSORGT',
    isMock: false
  },
  angenommeneDetails: {
    url: 'lagerAngenommene.json',
    isMock: true
  },
  abrufeAnfallstellenModalLevelOne: {
    url: 'Stammdaten/GetALL_V_ANFALLSTELLEForUser',
    isMock: false
  },
  abrufeAnfallstellenModalLevelTwo: {
    url: 'Stammdaten/GetALL_Typ1EntsorgungsleistungenByID_ABRUFORG',
    isMock: false
  },
  abrufeAnfallstellenModalLevelThree: {
    url: 'Stammdaten/GetGebindeAssignedByAbfstammID',
    isMock: false
  },
  abrufeErzeugerModalLevelOne: {
    url: 'Stammdaten/GetALL_V_TEILNEHMERForUser',
    isMock: false
  },
  abrufeErzeugerModalLevelTwo: {
    url: 'Stammdaten/GetALL_Typ2EntsorgungsleistungenByID_ABRUFORG',
    isMock: false
  },
  abrufeErzeugerModalLevelThree: {
    url: 'Stammdaten/GetGebindeAssignedByAbfstammID',
    isMock: false
  },
  abrufeSammlerModalLevelOne: {
    url: 'Stammdaten/GetALL_V_TEILNEHMERForUser',
    isMock: false
  },
  abrufeSammlerModalLevelTwo: {
    url: 'Stammdaten/GetALL_Typ3EntsorgungsleistungenByID_ABRUFORG',
    isMock: false
  },
  abrufeSammlerModalLevelThree: {
    url: 'Stammdaten/GetGebindeAssignedByAbfstammID',
    isMock: false
  },
  befordererList: {
    url: 'Stammdaten/GetAll_MoeglicheBefoerdererZurAkte',
    isMock: true
  },
  BegleitscheineNummer: {
    url: 'eANVDocumentService/DBO_GetALLNUMMER', // 'VorlagenBNummer.json',//
    isMock: false
  },
  getExtLogMenge: {
    url: 'Lager/GetBegleitscheinInfo',
    isMock: false
  },
  fromExternDispo: {
    url: 'AnalyticService/GetALL_V_INTERN_ENTSORGT_VonExtern', //'externalDispoFileData.json',
    isMock: false
  },
  toExternDispo: {
    url: 'Nachweis/GetALL_V_BGS_EXTByID_ENSN', //'toExternalDisposalList.json',
    isMock: false
  },
  disposalCertificateList: {
    url: 'eANVDocumentService/DBO_GetALLDocumentMetaStubView',
    isMock: false
  },
  vorlageFurBegleitscheineRenew: {
    url: 'vorlageFurBegleitscheineRenew.json',
    isMock: true
  },
  vorlageFurBegleitscheineDelete: {
    url: 'vorlageFurBegleitscheineDelete.json',
    isMock: true
  },
  eAnvList: {
    url: 'eANVDocumentService/DBO_GetALLENSNReferenzAKTEView',
    isMock: false
  },
  createNewEanv: {
    url: 'eANVDocumentService/DBO_CreateAndLinkENSN',
    isMock: false
  },
  lagerList: {
    url: 'lagerList.json',
    isMock: true
  },
  organisierenLager: {
    url: 'organisieren.json',
    isMock: true
  },
  lagerBestand: {
    url: 'Lager/GetALL_V_LAGERBESTANDByBereichIDs',
    isMock: false
  },
  abrufeFurAnfallstellenList: {
    url: 'Stammdaten/GetALL_Typ1AbrufeForUser',
    isMock: false
  },
  avvListWeiter: {
    url: 'avvList.json',
    isMock: true
  },
  externalEntsorgungLogbuch: {
    url: 'Lager/GetBegleitscheinNachExternDetails',
    isMock: false
  },
  updateExtLogbuch: {
    url: 'Lager/BegleitscheinNachExternAktualisieren',
    isMock: false
  },
  externalEntsorgungLogbuchPlanner: {
    url: 'AnalyticService/Get_EXTERN_ENTSORGT_LOGBOOK',
    isMock: false
  },
  lsDruckLogbuch: {
    url: 'Report/GetReport_Begleitscheine6Fach',
    isMock: false
  },
  lsSingleDruckDownload: {
    url: 'Report/GetReport_Begleitschein6Fach',
    isMock: false
  },
  updateRechnung: {
    url: 'AnalyticService/Update_TXT_ENTSORGUNGSRECHNUNG_TXT_TRANSPORTRECHNUNG',
    isMock: false
  },
  getMeineDokumente: {
    url: 'eANVDocumentService/DBO_GetALLDocumentMetaStubView',
    isMock: false
  },
  entsorgungsaktenList: {
    url: 'Nachweis/GetALL_V_ENSN',
    isMock: false
  },
  deleteEntsorgungsakten: {
    url: 'Nachweis/Nachweisakte_loeschen',
    isMock: false
  },
  entsorgungsaktenSingleData: {
    url: 'Nachweis/Nachweisakte_Selektieren',
    isMock: false
  },
  begleitscheineList: {
    url: 'eANVDocumentService/DBO_GetALLBGSReferenzABRUFView',
    isMock: false
  },
  getALLDocumentMetaStubView: {
    url: 'eANVDocumentService/DBO_GetALLDocumentMetaStubView',
    isMock: false
  },
  efgDocumente: {
    url: 'agsBescheidList.json',
    isMock: true
  },
  RegisterAnforderungen: {
    url: 'eANVDocumentService/DBO_GetALLDocumentMetaStubView',
    isMock: false
  },
  RegDataList: {
    url: 'quittungenDatalist.json',
    isMock: true
  },
  eventoEntsorgungsnachweiseList: {
    url: 'eANVDocumentService/DBO_GetALLDocumentMetaStubView',
    isMock: false
  },
  OSCIAddress: {
    url: 'Admin/Get_TotalCount_from_tbl_teilnehmer',
    isMock: false
  },
  OSCIJobUpdate: {
    url: 'Admin/Update_OSCI_Adressen_aktualisieren',
    isMock: false
  },
  ubernahmsecheinEventoListData: {
    url: 'eANVDocumentService/DBO_GetALLDocumentMetaStubView',
    isMock: false
  },
  registrierungList: {
    url: 'eANVDocumentService/Managed_DocumentRegistraars',
    isMock: true
  },
  aktionenList: {
    url: 'aktionenList.json',
    isMock: true
  },
  versandList: {
    url: 'eANVDocumentService/Managed_GetSendHistoryForDocument',
    isMock: false
  },
  empfangList: {
    url: 'eANVDocumentService/Managed_GetReceiveHistoryForDocument',
    isMock: false
  },
  quittungenList: {
    url: 'eANVDocumentService/Managed_GetNonLayerDocumentInfo',
    isMock: false
  },
  quittungenListDetails: {
    url: 'eANVDocumentService/Managed_GetNonLayerDocumentInfo',
    isMock: false
  },
  costCenterListApi: {
    url: 'Stammdaten/GetV_ORGKSTLAssignedToOrgUnit',
    isMock: false
  },
  gebindeFormDetails: {
    url: 'Stammdaten/GetSingle_GEBINDEFORM',
    isMock: false
  },
  afallFormDetails: {
    url: 'Abfall/GetSingle_V_ABFALLSTAMMByID',
    isMock: false
  },
  addCorporation: {
    url: 'Stammdaten/CreateKoerperschaft',
    isMock: false
  },
  editCorporation: {
    url: 'Stammdaten/GetSingle_KoerperschaftByID',
    isMock: false
  },
  updateCorporation: {
    url: 'Stammdaten/Update_Koerperschaft',
    isMock: false
  },
  producerOriginDelete: {
    url: 'Stammdaten/Delete_Anfallstelle',
    isMock: false
  },
  producerOriginCreate: {
    url: 'Stammdaten/Create_Anfallstelle',
    isMock: false
  },
  producerOriginUpdate: {
    url: 'Stammdaten/Update_Anfallstelle',
    isMock: false
  },
  zugang: {
    url: 'zugang.json',
    isMock: true
  },
  getReasonList: {
    url: 'eANVDocumentService/Managed_GetFullLayerDocumentInfo',
    isMock: false
  },
  getFehrelprotokoll: {
    url: 'fehlerProtokoll.json',
    isMock: true
  },
  nachforderungInformation: {
    url: 'NachforderungList.json',
    isMock: true
  },
  bimSchByAngfallstelle: {
    url: 'Stammdaten/GetALL_BImSchVByAnfallstelle',
    isMock: false
  },
  createBImSch: {
    url: 'Stammdaten/Create_BImSchV',
    isMock: false
  },
  updateBImSch: {
    url: 'Stammdaten/Update_BImSchV',
    isMock: false
  },
  deleteBImSch: {
    url: 'Stammdaten/Delete_BImSchV',
    isMock: false
  },
  validateBimSch: {
    url: 'Stammdaten/ValidateBImSchNummer',
    isMock: false
  },
  selectKostenstellenList: {
    url: 'Stammdaten/GetV_ORGKSTLAssignedToOrgUnit',
    isMock: false
  },
  kostenstellenList: {
    url: 'Stammdaten/GetNotOrgunitAssignedKOSTENSTELLE',
    isMock: false
  },
  kostenstellenListmock: {
    url: 'costCenter.json',
    isMock: true
  },
  assignKostellen: {
    url: 'Stammdaten/AssignKostenstellenToOrg',
    isMock: false
  },
  sieteErzeugerList: {
    url: 'sieteErzeugerList.json',
    isMock: true
  },
  seiteBimschgList: {
    url: 'SeiteBimschg.json',
    isMock: true
  },
  auswahlDeklarationsanalyseList: {
    url: 'auswahlDeklarationsanalys.json',
    isMock: true
  },
  fileattachmentList: {
    url: 'FileAttachedData.json',
    isMock: true
  },
  createTeilnehmer: {
    url: 'Stammdaten/Create_Teilnehmer',
    isMock: false
  },
  addProducer: {
    url: 'Stammdaten/Create_Teilnehmer',
    isMock: false
  },
  addProducerModal: {
    url: 'Stammdaten/GetALL_V_STANDORT',
    isMock: false
  },
  getAuswahlList: {
    url: 'eANVDocumentService/KomplexesAnlagenProfil',
    isMock: false
  },
  addZwischenlager: {
    url: 'Stammdaten/Create_Teilnehmer',
    isMock: false
  },
  getAllNachweisNumber: {
    url: 'Nachweis/GetALL_V_ENSN',
    isMock: false
  },
  abrufeDetailsAnfallstellen: {
    url: 'Stammdaten/GetSingle_Typ1Abruf',
    isMock: true
  },
  dateianhangeList: {
    url: 'dateianhangeList.json',
    isMock: true
  },
  befordererWahlenList: {
    url: 'stammdaten/GetAll_MoeglicheBefoerdererZurAkte',
    isMock: true
  },
  zumAbfallstammList: {
    url: 'Abfall/GetALL_V_ABFALLSTAMM',
    isMock: false
  },
  zurAnfallstellenList: {
    url: 'Stammdaten/GetALL_V_ANFALLSTELLE',
    isMock: false
  },
  zumAbfallStammRightGrid: {
    url: 'Stammdaten/GetALL_Typ1EntsorgungsleistungenByID_ABFSTAMM',
    isMock: false
  },
  zurAnfallstelleRightGrid: {
    url: 'Stammdaten/GetALL_Typ1EntsorgungsleistungenByID_ABRUFORG',
    isMock: false
  },
  abrufeFurErzeugerList: {
    url: 'Stammdaten/GetALL_Typ2AbrufeForUser',
    isMock: false
  },
  abrufeFurSammlerList: {
    url: 'Stammdaten/GetALL_Typ3AbrufeForUser',
    isMock: false
  },
  signaturenList: {
    url: 'eANVDocumentService/Managed_ValidateAllSignatures',
    isMock: false
  },
  versendenInnerList: {
    url: 'versendenInnerList.json',
    isMock: true
  },
  entsorgungsakteDropdown: {
    url: 'Nachweis/GetALL_TBL_NWTYP',
    isMock: false
  },
  deleteMogPointOfOrigin: {
    url: 'Stammdaten/Delete_MoeglicheBefoerderer'
  },
  updateProducer: {
    url: 'Stammdaten/Update_Teilnehmer',
    isMock: false
  },
  mitteilungenList: {
    url: 'eANVDocumentService/DBO_GetALLDocumentMetaStubView',
    isMock: false
  },
  mitteilungenModalList: {
    url: 'eANVDocumentService/GetALL_Mitteilungszweck',
    isMock: false
  },
  mitteilungenfileUploadList: {
    url: 'mitteilungenFileUpload.json',
    isMock: true
  },
  updateZwischenlager: {
    url: 'Stammdaten/Update_Teilnehmer',
    isMock: false
  },
  deleteCorporation: {
    url: 'Stammdaten/Delete_Koerperschaft',
    isMock: false
  },
  saveEntsorgungsakteCreatedData: {
    url: 'Nachweis/Nachweisakte_erstellen',
    isMock: false
  },
  updateEntsorgungsaktenData: {
    url: 'Nachweis/Nachweisakte_aktualisieren',
    isMock: false
  },
  saveBgsTemplateData: {
    url: 'Nachweis/CreateOrUpdateBGSTemplate_ForENSN',
    isMock: false
  },
  saveUnsTemplateData: {
    url: 'Nachweis/CreateOrUpdateUNSTemplate_ForENSN',
    isMock: false
  },
  saveEntsorgungsnachweiseData: {
    url: 'eANVDocumentService/Managed_SetView',
    isMock: false
  },
  deleteEanv: {
    url: 'eANVDocumentService/DBO_RemoveLinks_REFENSN',
    isMock: false
  },
  renewBgsTemplateData: {
    url: 'Nachweis/CreateOrUpdateStandardBGSTemplate_ForENSN',
    isMock: false
  },
  renewUnsTemplateData: {
    url: 'Nachweis/CreateOrUpdateStandardUNSTemplate_ForENSN',
    isMock: false
  },
  KonfliktdokumenteList: {
    url: 'Teilnehmer/FindDocumentsPOST',
    isMock: false
  },
  BGSNrAnforderungenList: {
    url: 'eANVDocumentService/DBO_GetALLDocumentMetaStubView',
    isMock: false
  },

  daDokumente: {
    url: 'eANVDocumentService/DBO_GetALLDocumentMetaStubView',
    isMock: false
  },
  quittungenDataList: {
    url: 'eANVDocumentService/DBO_GetALLDocumentMetaStubView',
    isMock: false
  },
  mitteilungenRegistrierungList: {
    url: 'registrierungList.json',
    isMock: true
  },
  mitteilungenAktionenList: {
    url: 'eANVDocumentService/DBO_GetDocumentActionHistory',
    isMock: false
  },
  mitteilungenVersandList: {
    url: 'eANVDocumentService/Managed_GetSendHistoryForDocument',
    isMock: false
  },
  mitteilungenEmpfangList: {
    url: 'eANVDocumentService/Managed_GetReceiveHistoryForDocument',
    isMock: false
  },
  mitteilungenQuittungenList: {
    url: 'eANVDocumentService/Managed_GetNonLayerDocumentInfo',
    isMock: false
  },
  mitteilungenQuittungenListDetails: {
    url: 'eANVDocumentService/Managed_GetNonLayerDocumentInfo',
    isMock: false
  },

  mitteilungenSignaturenList: {
    url: 'eANVDocumentService/Managed_ValidateAllSignatures',
    isMock: false
  },
  mitteilungenVersendenInnerList: {
    url: 'versendenInnerList.json',
    isMock: true
  },
  egfUploadedDocumentList: {
    url: 'egfDocument.json',
    isMock: true
  },
  getStandortByKID: {
    url: 'Stammdaten/GetALL_StandortByKoerperschaftID',
    isMock: false
  },
  abrufeDetailsErzeuger: {
    url: 'Stammdaten/GetSingle_Typ2Abruf',
    isMock: true
  },
  abrufeDetailsSammler: {
    url: 'Stammdaten/GetSingle_Typ3Abruf',
    isMock: true
  },
  beendteAbrufeFurAnfallstellenList: {
    url: 'Stammdaten/GetALL_Typ1AbrufeForUser',
    isMock: false
  },
  beendteAbrufeFurErzeugerList: {
    url: 'Stammdaten/GetALL_Typ2AbrufeForUser',
    isMock: false
  },
  beendteAbrufeFurSammlerList: {
    url: 'Stammdaten/GetALL_Typ3AbrufeForUser',
    isMock: false
  },

  internalBilling: {
    url: 'Stammdaten/GetALL_V_ANFALLSTELLEForUser',
    isMock: false
  },
  egfAktionenList: {
    url: 'aktionenList.json',
    isMock: true
  },
  egfVersandList: {
    url: 'versandList.json',
    isMock: true
  },
  egfEmpfangList: {
    url: 'empfangList.json',
    isMock: true
  },
  egfQuittungenList: {
    url: 'quittungenList.json',
    isMock: true
  },
  egfSignaturenList: {
    url: 'signaturenList.json',
    isMock: true
  },
  ubersichtList: {
    url: 'Teilnehmer/FindDocuments',
    isMock: false
  },
  externalEntsorgenList: {
    url: 'Nachweis/GetALL_V_ENSN',
    isMock: false
  },
  detachKostellen: {
    url: 'Stammdaten/DetachKostenstellenFromOrg',
    isMock: false
  },
  createAbrufeTemplate: {
    url: 'Stammdaten/Abruf_erstellen_als_Vorlage',
    isMock: false
  },
  createAbrufe: {
    url: 'Stammdaten/Abruf_erstellen_und_einreichen',
    isMock: false
  },
  arufeDispoInfo: {
    url: 'Stammdaten/GetSingle_Entsorgungseinheit',
    isMock: false
  },
  deleteAbrufe: {
    url: 'Stammdaten/Abrufvorlage_loeschen',
    isMock: false
  },
  saveAbrufeDispo: {
    url: 'Stammdaten/Abruf_Korrektur',
    isMock: false
  },
  lagerzuordnungLists: {
    url: 'Stammdaten/GetTeilnehmerLagerByID_TEILNEHMER', //Stammdaten/GetTeilnehmerLagerByID_TEILNEHMER_ddl
    isMock: false
  },
  allModulesMenuItems: {
    url: 'Admin/FetchALL_UserAppModulesByApplication',
    isMock: false
  },
  interneAbrechungAnfallstelleReports: {
    url: 'Report/GetReport_Monatsbericht',
    isMock: false
  },
  generateInternerScheinReport: {
    url: 'Report/GetReport_InternerSchein',
    isMock: false
  },
  getAutoDispoList: {
    url: 'Stammdaten/GetTeilnehmerLagerByID_TEILNEHMER',
    isMock: false
  },
  getAutoDispoSelectedValue: {
    url: 'Stammdaten/GetEWegAutoDispLager',
    isMock: false
  },
  updatePointOfOrigin: {
    url: 'Stammdaten/Update_EWEG',
    isMock: false
  },
  lagerzuordnungModalList: {
    url: 'Stammdaten/GetALL_V_LAGER',
    isMock: false
  },
  updateLagerzuordnung: {
    url: 'Stammdaten/AssignLagerToTeilnehmer',
    isMock: false
  },
  deleteLagerzuordnung: {
    url: 'Stammdaten/RemoveLagerToTeilnehmerAssignment',
    isMock: false
  },
  typ1SingleDispoData: {
    url: 'Stammdaten/GetSingle_Typ1Abruf',
    isMock: false
  },
  saveIndividualReport: {
    url: 'AnalyticService/Create_REPORT',
    isMock: false
  },
  saveEditIndividualReport: {
    url: 'AnalyticService/Update_REPORT',
    isMock: false
  },
  downloadFile: {
    url: 'Report/GetReport_DownloadAttachment'
  },
  downloadFileByUuid: {
    url: 'Report/GetReport_DownloadAttachmentByUUID'
  },

  deleteTeilnehmer: {
    url: 'Stammdaten/Delete_Teilnehmer',
    isMock: false
  },
  deleteZwischenlager: {
    url: 'Stammdaten/Delete_Teilnehmer',
    isMock: false
  },
  updateGebindeformenList: {
    url: 'Stammdaten/AssignGebindeToAbfstamm',
    isMock: false
  },
  UpdateGebindeformAssignmentList: {
    url: 'Stammdaten/UpdateGebindeformAssignment',
    isMock: false
  },
  DetachGebindeFromAbfstammList: {
    url: 'Stammdaten/DetachGebindeFromAbfstamm',
    isMock: false
  },
  dispositionData: {
    url: 'Stammdaten/GetALL_EntsorgungseinheitenByID_ABRUF',
    isMock: false
  },
  lagerBestandByBereichIds: {
    url: 'Lager/GetALL_V_LAGERBESTANDByBereichIDs',
    isMock: false
  },
  teilnehmerSingle: {
    url: 'Stammdaten/GetSingle_TeilnehmerByID',
    isMock: false
  },
  lagerByBereichById: {
    url: 'Stammdaten/GetALL_LagerbereichByID_LAGER',
    isMock: false
  },

  zumAbfallStamnAttach: {
    url: 'Stammdaten/ErstelleTyp1Entsorgungsleistungen',
    isMock: false
  },
  zumAbfallStamnDetach: {
    url: 'Stammdaten/LoescheTyp1Entsorgungsleistungen',
    isMock: false
  },
  zumAbfallStamSammlerAttach: {
    url: 'Stammdaten/ErstelleTyp3Entsorgungsleistungen',
    isMock: false
  },
  zumAbfallStamSammlerDetach: {
    url: 'Stammdaten/LoescheTyp3Entsorgungsleistungen',
    isMock: false
  },
  dispoType: {
    url: 'Stammdaten/GetSingle_VAbruf',
    isMock: false
  },
  typ2SingleDispoData: {
    url: 'Stammdaten/GetSingle_Typ2Abruf',
    isMock: false
  },
  typ3SingleDispoData: {
    url: 'Stammdaten/GetSingle_Typ3Abruf',
    isMock: false
  },
  deleteVeroffentlichungList: {
    url: 'AnalyticService/DetachReportFromUsers',
    isMock: false
  },
  ausWahlBenutzerAdd: {
    url: 'AnalyticService/AssignReportToUsers',
    isMock: false
  },
  uploadFile: {
    url: 'eANVDocumentService/Managed_SaveUploadedFiles',
    isMock: false
  },
  internalEntsorgungList: {
    url: 'Stammdaten/GetALL_V_TEILNEHMERForUserWithPaging',
    isMock: false
  },
  deleteFile: {
    url: 'Stammdaten/Delete_Attachment',
    isMock: false
  },
  removeAttachment: {
    url: 'eANVDocumentService/Managed_RemoveAttachment',
    isMock: false
  },
  geloschteDokumenteDelete: {
    url: 'eANVDocumentService/DBO_MultiPermaDeleteANVDocument'
  },
  rejectCall: {
    url: 'Stammdaten/Abruf_Ablehnen',
    isMock: false
  },
  updateDispoCall: {
    url: 'Stammdaten/CreateDispositionUnit',
    isMock: false
  },
  listTyp2Dispositionsweg: {
    url: 'Stammdaten/GetALL_Typ2EWegeByID_ERZEUGUNG',
    isMock: false
  },
  listTyp3Dispositionsweg: {
    url: 'Stammdaten/GetALL_Typ3EWegeByID_ERZEUGUNG',
    isMock: false
  },
  downloadIndividualReport: {
    url: 'Report/GetReport_CustomReport',
    isMock: false
  },
  generateSubmitAbrufeReports: {
    url: 'Report/GetReport_Abrufbestaetigung',
    isMock: false
  },
  generateDeniedAbrufeReports: {
    url: 'Report/GetReport_Abrufabgelehnt',
    isMock: false
  },
  abrufeEditVorlage: {
    url: 'Stammdaten/Abrufvorlage_aktualisieren',
    isMock: false
  },
  abrufeEditSent: {
    url: 'Stammdaten/Abruf_senden_aus_Vorlage',
    isMock: false
  },
  Abruf_Korrektur: {
    url: 'Stammdaten/Abruf_Korrektur',
    isMock: false
  },
  createWayofDisposal: {
    url: 'Stammdaten/Create_EWEG',
    isMock: false
  },
  deleteWayofDisposal: {
    url: 'Stammdaten/Delete_EWEG',
    isMock: false
  },
  erzeugerUpdate: {
    url: 'Stammdaten/UpdateTyp2Entsorgungsleistungen',
    ismock: false
  },

  postIntreneNachAnlieferung: {
    url: 'Lager/InterneAbfallEntsorgungNachEinheiten',
    isMock: false
  },
  postIntreneNachMenge: {
    url: 'Lager/InterneAbfallEntsorgungNachMenge',
    isMock: false
  },

  uploadCertificate: {
    url: 'Teilnehmer/UploadCertificate',
    isMock: false
  },
  downloadCertificate: {
    url: 'Report/GetReport_CerificateDownload',
    isMock: false
  },
  deleteCertificate: {
    url: 'Teilnehmer/DeleteCertificate',
    isMock: false
  },
  addNewProvider: {
    url: 'Stammdaten/Create_Teilnehmer',
    isMock: false
  },
  singleProviderData: {
    url: 'Stammdaten/GetSingle_TeilnehmerByID',
    isMock: false
  },
  updateProviderData: {
    url: 'Stammdaten/Update_Teilnehmer',
    isMock: false
  },
  deletProvider: {
    url: 'Stammdaten/Delete_Teilnehmer',
    isMock: false
  },
  externalEntsorgenLogbuchData: {
    url: 'GetALL_V_EXTERN_ENTSORGT',
    isMock: false
  },
  deleteLeistungsart: {
    url: 'Stammdaten/Delete_LEISTUNGSART',
    isMock: false
  },
  updatePerticipant: {
    url: 'Teilnehmer/Update_Participant',
    isMock: false
  },
  updateSWIAktuell: {
    url: 'Teilnehmer/Update_SWI_Aktuell',
    isMock: false
  },
  certificateValidate: {
    url: 'Teilnehmer/ListCertificatesForValidationDb',
    isMock: false
  },
  aktionenListEvento: {
    url: 'eANVDocumentService/DBO_GetDocumentActionHistory',
    isMock: false
  },
  validateSchemaEvento: {
    url: 'eANVDocumentService/Managed_ValidateDocument',
    isMock: false
  },
  ubernahmescheinEditData: {
    url: 'eANVDocumentService/Managed_GetFullLayerDocumentInfo',
    isMock: false
  },
  absenderDetails: {
    url: 'eANVDocumentService/GetAbsenderProvider'
  },
  lockUnlockCostCenter: {
    url: 'Stammdaten/LockUnLoclKostenstellenOrg',
    isMock: false
  },
  egfEditDokumentEvento: {
    url: 'eANVDocumentService/Managed_GetNonLayerDocumentInfo',
    isMock: false
  },
  quittungenHistoryList: {
    url: 'eANVDocumentService/DBO_GetDocumentActionHistory',
    isMock: false
  },
  bGSNrAnforderungenEdit: {
    url: 'eANVDocumentService/Managed_GetNonLayerDocumentInfo',
    isMock: false
  },
  bgsNrAnforderFileInfo: {
    url: 'eANVDocumentService/Managed_GetDocumentAttachmentInfos',
    isMock: false
  },
  deleteBGSNrDocument: {
    url: 'eANVDocumentService/Managed_DeleteDocument',
    isMock: false
  },
  bgsNrDocCheckin: {
    url: 'eANVDocumentService/DBO_CheckInANVDocument',
    isMock: false
  },
  bgsNrDocCheckOut: {
    url: 'eANVDocumentService/DBO_CheckOutANVDocument',
    isMock: false
  },

  saveVersendenEvento: {
    url: 'eANVDocumentService/Managed_ScheduleDocument',
    isMock: false
  },
  registerUnregisterEvento: {
    url: 'eANVDocumentService/Managed_SetRegister',
    isMock: false
  },
  deleteDokumentTempEvento: {
    url: 'eANVDocumentService/Managed_DeleteDocument',
    isMock: false
  },
  submitMeineDokumente: {
    url: 'eANVDocumentService/DBO_MultiCheckInANVDocument',
    isMock: false
  },
  unDeleteDokumentTempEvento: {
    url: 'eANVDocumentService/Managed_UndeleteDocument',
    isMock: false
  },
  uploadBGSDocuments: {
    url: 'eANVDocumentService/Managed_AttachUploadedFiles',
    isMock: false
  },
  bgsNrAnforderungEditSave: {
    url: 'eANVDocumentService/Managed_SetContent',
    isMock: false
  },
  deleteDokumentLayerEvento: {
    url: 'eANVDocumentService/Managed_DeleteLayer',
    isMock: false
  },
  setStornoEvento: {
    url: 'eANVDocumentService/Managed_SetStorno',
    isMock: false
  },
  setCheckIn: {
    url: 'eANVDocumentService/DBO_CheckInANVDocument',
    isMock: false
  },
  setCheckOut: {
    url: 'eANVDocumentService/DBO_CheckOutANVDocument',
    isMock: false
  },
  updateEntsorgungNachweiseValue: {
    url: 'eANVDocumentService/Update_INDIKREGISTER',
    isMock: false
  },
  bgsNrZuteilungModal: {
    url: 'eANVDocumentService/Managed_GetNonLayerDocumentInfo',
    isMock: false
  },
  saveBGSNEUDataNachMenge: {
    url: 'Lager/ExterneAbfallEntsorgungNachMenge_eANV_BGS_NEU',
    isMock: false
  },
  saveBGSEXIDataNachMenge: {
    url: 'Lager/ExterneAbfallEntsorgungNachMenge_eANV_BGS_EXI',
    isMock: false
  },
  saveUNSNEUDataNachMenge: {
    url: 'Lager/ExterneAbfallEntsorgungNachMenge_eANV_UNS_NEU',
    isMock: false
  },
  saveUNSEXIDataNachMenge: {
    url: 'Lager/ExterneAbfallEntsorgungNachMenge_eANV_UNS_EXI',
    isMock: false
  },
  saveBGSNEUDataNachAnlie: {
    url: 'Lager/ExterneAbfallEntsorgungNachEinheiten_eANV_BGS_NEU ',
    isMock: false
  },
  saveBGSEXIDataNachAnlie: {
    url: 'Lager/ExterneAbfallEntsorgungNachEinheiten_eANV_BGS_EXI',
    isMock: false
  },
  saveUNSNEUDataNachAnlie: {
    url: 'Lager/ExterneAbfallEntsorgungNachEinheiten_eANV_UNS_NEU',
    isMock: false
  },
  saveUNSEXIDataNachAnlie: {
    url: 'Lager/ExterneAbfallEntsorgungNachEinheiten_eANV_UNS_EXI',
    isMock: false
  },
  saveLiefscrienMengeData: {
    url: 'Lager/ExterneAbfallEntsorgungNachMenge',
    isMock: false
  },
  saveLiefscrienAnlieData: {
    url: 'Lager/ExterneAbfallEntsorgungNachEinheiten',
    isMock: false
  },
  checkoutAVVDokumente: {
    url: 'eANVDocumentService/DBO_CheckOutANVDocument',
    isMock: false
  },
  lieferscheinTabData: {
    url: 'stammdaten/GetAll_MoeglicheBefoerdererZurAkte',
    isMock: false
  },
  saveUnsEditEvento: {
    url: 'eANVDocumentService/Managed_SetView',
    isMock: false
  },
  downloadQuittungsbeleg: {
    url: 'Report/GetReport_Quittungsbeleg',
    isMock: false
  },
  downloadStruktur: {
    url: 'Report/GetReport_eXMLDokument',
    isMock: false
  },
  downloadDruck: {
    url: 'Report/Druckansicht',
    isMock: false
  },
  downloadDruckDocument: {
    url: 'Report/GetPrint_HTML',
    isMock: false
  },
  saveReportConditions: {
    url: 'AnalyticService/Create_WhereRestriction',
    isMock: false
  },

  registerReceiptDetails: {
    url: 'eANVDocumentService/Managed_GetNonLayerDocumentInfo',
    isMock: false
  },
  lagerzuordnungActiveLists: {
    url: 'Stammdaten/GetTeilnehmerLagerByID_TEILNEHMER_ddl',
    isMock: false
  },
  mitteilungenDeleteDockument: {
    url: 'eANVDocumentService/Managed_RemoveAttachment',
    isMock: false
  },
  deleteExtLogbuch: {
    url: 'Lager/BegleitscheinNachExternLoeschen',
    isMock: false
  },
  daDokumenteEditDetails: {
    url: 'eANVDocumentService/Managed_GetNonLayerDocumentInfo',
    isMock: false
  },
  uploadMitteilungFile: {
    url: 'eANVDocumentService/Managed_AttachUploadedFiles',
    isMock: false
  },
  getSingleSammlerList: {
    url: 'Stammdaten/GetSingle_Typ3Entsorgungsleistungen',
    isMock: false
  },
  updateSammler: {
    url: 'Stammdaten/UpdateTyp3Entsorgungsleistungen',
    ismock: false
  },
  EditSammlerEntsorgungswegTableData: {
    url: 'Stammdaten/GetALL_Typ3EWegeByID_ERZEUGUNG',
    isMock: false
  },
  addnewEgf: {
    url: 'eANVDocumentService/Managed_CreateDocument',
    isMock: false
  },
  entsorgensaktenKommentar: {
    url: 'Stammdaten/GetALL_TBL_COMMENT_ENByID',
    isMock: false
  },

  updateEntsorgensaktenKommentar: {
    url: 'Stammdaten/Update_TBL_COMMENT_ENSN',
    isMock: false
  },

  insertEntsorgensaktenKommentar: {
    url: 'Stammdaten/Insert_TBL_COMMENT_ENSN',
    isMock: false
  },
  deleteReport: {
    url: 'AnalyticService/Delete_REPORT'
  },
  startSignatureByUUID: {
    url: 'Signature/StartSignatureByUUID',
    isMock: false
  },
  eventoDownloadFile: {
    url: 'Report/GetReport_DownloadAttachmentByUUID',
    isMock: false
  },
  getSingleErzeuger: {
    url: 'Stammdaten/GetSingle_Typ2Entsorgungsleistungen',
    isMock: false
  },
  teilnehmerUpdatedList: {
    url: 'Teilnehmer/FindTeilnehmer',
    isMock: false
  },
  registerCheckout: {
    url: 'Teilnehmer/CheckOutDocument',
    isMock: false
  },
  registerCheckin: {
    url: 'Teilnehmer/CheckInDocument',
    isMock: false
  },
  registerCreate: {
    url: 'Teilnehmer/CreateRegisterAuszug',
    isMock: false
  },
  bgsNrNew: {
    url: 'eANVDocumentService/Managed_CreateDocument',
    isMock: false
  },
  registerAuszugModalXMLData: {
    url: 'Teilnehmer/LoadDocument_RegisterAuszug',
    isMock: false
  },
  StartSignatureByClientID: {
    url: 'Signature/StartSignatureByClientID',
    isMock: false
  },
  registerTeilauzugtab: {
    url: 'Teilnehmer/FindDocumentsByRootUuid',
    isMock: false
  },
  registerAuszugeDetailsList: {
    url: 'Teilnehmer/LoadDocument',
    isMock: false
  },
  downloadRegisterAuszugXML: {
    url: 'Report/GetReport_eXMLDokument',
    isMock: false
  },
  eANVadd_new_fromlist: {
    url: 'eANVDocumentService/DBO_AddLink_REFENSN',
    isMock: false
  },
  bgsNrZuteilungHistory: {
    url: 'eANVDocumentService/DBO_GetDocumentActionHistory',
    isMock: false
  },
  bgsMengepopupattach: {
    url: 'eANVDocumentService/DBO_AddLinks_REFABRUF',
    isMock: false
  },
  bgsMengepopupdetach: {
    url: 'eANVDocumentService/DBO_RemoveLinks_REFABRUF',
    isMock: false
  },
  kriterienList: {
    url: 'kriterienList.json',
    isMock: true
  }
};
