export const NO_DATA_AVAILABLE = 'No data available';
export const REQUIRED_ERROR_MSG = 'Dieses Feld ist erforderlich';
export const REQUIRED_ERROR_MSG_LOCATION = 'ein Standort muss ausgewählt werden';
export const NUMBER_ERROR_MSG = 'Ungültige Nummer';
export const EMAIL_ERROR_MSG = 'Invalid email';
export const MIN_LENGTH_ERROR_MSG = 'Minimum {x} characters required';
export const MAX_LENGTH_ERROR_MSG = 'Maximum {x} characters allowed';
export const EXACT_LENGTH_ERROR_MSG = 'Exactly {x} characters required';
export const PATTERN_ERROR_MSG = 'Invalid input format';
export const PASSWORD_ERROR_MSG = 'Password and confirm password should be the same';

export const ALLOCATION_MODAL_MSG =
  'Hinweis:Es werden alle Einheiten abgerechnet,die bisher noch nicht in einem ***bestätigten*** Abrechnungslauf erfasst wurden und deren "Datum der Annahme" vor dem Stichtag liegt';
export const CLEARREQ_FORM_MSG = 'Hinweis: die Abrufnummer sollte gültig sein';
export const MIN_DATE_ERROR_MSG = 'Min date cannot be greater than max date';
export const MAX_DATE_ERROR_MSG = 'Max date cannot be less than min date';
export const START_DATE_ERROR_MSG = 'Das Startdatum darf nicht nach dem Enddatum liegen.';
export const END_DATE_ERROR_MSG = 'Das Enddatum darf nicht kleiner als das Startdatum sein.';

export const REQUIRED_CHAR_ERROR_MSG = 'Passwort muss aus mindestens 8 Zeichen bestehen';
export const REQUIRED_DIGIT_MSG = 'Passwort muss mindestens eine Ziffer enthalten';
export const REQUIRED_LOWERUPPER_MSG =
  'Passwort muss mindestens einen Kleinbuchstaben und einen Großbuchstaben enthalten';
export const REQUIRED_NUMBER_ERROR_MSG = 'Nr. muss angegeben werden';
export const REQUIRED_ANZAHLSTUCK_VALUE_ERROR_MSG = 'Anzahl Stück muss größer als 0 sein';
export const REQUIRED_MENGE_VALUE_ERROR_MSG = 'Menge in Tonnen muss größer als 0 sein';
export const REQUIRED_ANZAHL_ERROR_MSG = 'Die Eingabe ist nicht korrekt!';
export const REQUIRED_ABFALLVOLUMEN_ERROR_MSG =
  'Volumen in m3 muss -wenn angegeben- größer als 0 sein';

export const REQUIRED_KOSTELLE_ERR_MGS = 'Kostenstellenschlüssel muss angegegeben werden.';
export const CHECKBOX_UNCHECKED = 'unchecked';
export const CHECKBOX_CHECKED = 'checked';
export const CHECKBOX_INDETERMINATE = 'indeterminate';

export const BGSANFORDER_FIELDS_ERR_MSG =
  'Bitte füllen sie alle Felder aus (Beh. Nummer, Rolle, Art der Nummer and Anzahl)';
export const INVALID_ROLLE_ERR_MSG = 'Bitte nutzen sie nur die vorgegeben Rollen';
export const INVALID_BGSNUMTYP_ERR_MSG = 'Bitte nutzen sie nur UNS oder BGS';

// Teilnehmer module checkbox labels
export const eANVCHECKBOX_LABEL = 'im eANV soll die Postfachadresse verwendet werden.';
export const fuhrt_seinCHECKBOX_LABEL = '...führt sein Register im angeschlossenen eANV-Portal';
export const verwendetCHECKBOX_LABEL = '...verwendet derzeit das Quittungsbelegverfahren';
export const ist_eanvCHECKBOX_LABEL = '...ist eANV-Teilnehmer';
export const Gesperrtlabel = 'Gesperrt';

export const NAME_FORM_CHAR_LIMIT = 35;
export const BEHORDLICHE_FIELD_CHAR_LIMIT = 9;
