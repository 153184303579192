import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { loaderSubject } from 'helpers/loader.helper';
import { getFromStorage } from 'core/utils/utils.helper';
import { STORAGE_ACCESS_TOKEN } from 'constants/storage';

export const errorSubject = new BehaviorSubject(null);

const requests: any[] = []; // a global array to keep all active http requests

/**
 * Remove a request from global array once it is completed
 * @param req
 */
const removeRequest = (req: any) => {
  const i = requests.indexOf(req);
  if (i >= 0) {
    requests.splice(i, 1);
  }
  loaderSubject.next(requests.length > 0);
};

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    const token = getFromStorage(STORAGE_ACCESS_TOKEN);
    if (config && config.headers) {
      config.headers.Authorization = 'Bearer ' + token;
    }
    requests.push(config); // add request to global request array
    loaderSubject.next(true); // set loader to true
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    removeRequest(response.config); // remove request from global request array
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    removeRequest(error.config); // remove request from global request array
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status === 401) {
      // refresh the token
      // return authenticateCdpApi()
      //   ?.then((response: any) => {
      //     setToStorage(STORAGE_ACCESS_TOKEN, response.data.access_token);
      //     return axios.request(error.config);
      //   })
      //   .catch(() => {
      //     return Promise.reject(error);
      //   });
    } else {
      errorSubject.next(error);
    }
  }
);
